import { Autocomplete, TextField } from '@mui/material';
import { useState, SyntheticEvent, memo } from 'react';
import { useNavigate } from 'react-router';
import { SharedState } from '../sharedState/SharedState';
import { resolveValue } from '../source/Source';

/***
 * A text filter component that allows the user to enter a search query.
 * The search query is added to the page URL as a query string parameter.
 */
export const TextFilter = memo(function TextFilter(props: {
  sharedState: SharedState,
  redirect: String,
  autocompleteProps: any,
  textfieldProps: any,
  label: string,
}) {

  const useNavigateOnClient = props.sharedState.isClient ? useNavigate : () => () => { };

  /**
   * Use history to change the search query by updating the page URL
   */
  const navigate = useNavigateOnClient();

  const [searchword, setSearchword] = useState<string | null>(props.sharedState.filters.text || null);

  const setFilter = (value: string | null) => {
    const nextState = props.sharedState.clone();
    nextState.filters.text = value;

    // If the user does a search, remove the help id filter so they can search in the whole site
    delete nextState.filters.system["hid"];

    if (props.redirect) {
      nextState.urlPath = `${nextState.sitePath}${props.redirect}`;
    }

    if (nextState.urlPath === props.sharedState.urlPath) {
      // Let React handle the navigation
      navigate(nextState.getUrl());
    }
    else {
      // Use browser navigation to update the URL instead of React Router
      // This way the new page will get loaded from the server
      window.location.href = nextState.getUrl();
    }
  }

  const textfieldProps = props.textfieldProps || {};
  textfieldProps.label = resolveValue(props.sharedState, props.label || textfieldProps.label || "Search", false)

  return <Autocomplete freeSolo {...props.autocompleteProps}
    id="textfilter-autocomplete"
    options={[]}
    value={searchword}
    onChange={(event: SyntheticEvent<Element, Event>, newVal: string | null) => {
      setSearchword(newVal);
      setFilter(newVal);
    }}
    renderInput={(params: any) => {
      return <TextField {...params} {...textfieldProps} type="search" />
    }}
  />;
});