/***
 * Array sources are used to retrieve arrays of values from the shared state.
 * They are used in specific components that expect array source specification as input.
 */

import { CompareSource } from "./arraysource/compare/CompareSource";
import { ConstantSource } from "./arraysource/constant/ConstantSource";
import { QueryBaselineSource } from "./arraysource/query/QueryBaselineSource";
import { QueryCustomNamesSource } from "./arraysource/query/QueryCustomNamesSource";
import { QueryCustomValuesSource } from "./arraysource/query/QueryCustomValuesSource";
import { QuerySearchSource } from "./arraysource/query/QuerySearchSource";
import { QuerySystemValuesSource } from "./arraysource/query/QuerySystemValuesSource";
import { QueryUsedbySource } from "./arraysource/query/QueryUsedbySource";
import { QueryValidityValuesSource } from "./arraysource/query/QueryValidityValuesSource";
import { StoreArraySource } from "./arraysource/store/StoreArraySource";
import { PageScanTreeSource } from "./arraysource/page/PageScanTreeSource";
import { SourceItem } from "../models/source-item.model";
import { SharedState } from "../sharedState/SharedState";
import { QueryCurrentMetadataSource } from "./arraysource/query/QueryCurrentMetadataSource";
import { LocationItemSource } from "./arraysource/location/LocationItemSource";
import { MergeSource } from "./arraysource/merge/MergeSource";
import { PropertyNamesSource } from "./arraysource/property/PropertyNamesSource";
import { PropertyValuesSource } from "./arraysource/property/PropertyValuesSource";
import { ValidityMatrixSource } from "./arraysource/validity/ValidityMatrixSource";
import { ChildrenArraySource } from "./arraysource/children/ChildrenArraySource";
import { FilterSource } from "./arraysource/filter/FilterSource";

export type ArraySource = {
    getValue: (sharedState: SharedState, params: { [name: string]: string; }) => Promise<Array<SourceItem>>;
};

export type ArraySourceRequest = {
    source: ArraySource | null;
    params: { [name: string]: any; };
};

export const arraySourceMap: Array<{ source: ArraySource, regex: RegExp }> = [
    /***
     * Children sources
    */
    {
        source: new ChildrenArraySource(),
        regex: /^children\.(?<store>.+)$/,
    },

    /***
     * Constant sources
    */
    {
        source: new ConstantSource(),
        regex: /^constant\.(?<values>.+)$/,
    },

    /***
     * Difference source
    */
    {
        source: new CompareSource(),
        regex: /^compare\.(?<category>.+)\.(?<baseline>.+)\.(?<current>.+)$/,
    },

    /***
     * Fitler source
    */
    {
        source: new FilterSource(),
        regex: /^filter\.(?<type>.+)\.(?<name>.+)$/,
    },

    /***
     * Location source
    */
    {
        source: new LocationItemSource(),
        regex: /^location$/,
    },

    /***
     * Merge source
    */
    {
        source: new MergeSource(),
        regex: /^merge.(?<stores>.+)$/,
    },

    /***
     * Page source
     */
    {
        source: new PageScanTreeSource(),
        regex: /^page\.tree\.(?<rootdef>.+)(?<!\\)\.(?<branchdef>.+)(?<!\\)\.(?<labeldef>.+)$/,
    },

    /***
     * Properties source
     */
    {
        source: new PropertyNamesSource(),
        regex: /^property\.(?<store>[^.]+)\.(?<type>[^.]+)$/,
    },
    {
        source: new PropertyValuesSource(),
        regex: /^property\.(?<store>.+)\.(?<type>.+)\.(?<name>.+)$/,
    },


    /***
     * Query source
     */
    {
        source: new QueryBaselineSource(),
        regex: /^query\.baseline$/,
    },
    {
        source: new QueryCurrentMetadataSource(),
        regex: /^query\.current$/,
    },
    {
        source: new QueryCustomNamesSource(),
        regex: /^query\.names\.custom$/,
    },
    {
        source: new QuerySearchSource(),
        regex: /^query\.search(\.(?<format>.+))?$/,
    },
    {
        source: new QueryCustomValuesSource(),
        regex: /^query\.values\.custom\.(?<name>.+)$/,
    },
    {
        source: new QuerySystemValuesSource(),
        regex: /^query\.values\.system\.(?<name>.+)$/,
    },
    {
        source: new QueryValidityValuesSource(),
        regex: /^query\.values\.validity\.(?<name>.+)$/,
    },
    {
        source: new QueryUsedbySource(),
        regex: /^query\.usedby\.(?<path>.+)$/,
    },

    /***
     * Store source
     */
    {
        source: new StoreArraySource(),
        regex: /^store\.(?<store>.+)$/,
    },

    /***
     * Validity source
     */
    {
        source: new ValidityMatrixSource(),
        regex: /^validity\.(?<store>[^.]+)\.(?<properties>.+)$/,
    },
];

function removeProtect(params: { [key: string]: string }): { [key: string]: string } {
    const result: { [key: string]: string } = {};
    for (const key in params) {
        result[key] = params[key]?.replaceAll("\\$", "$").replaceAll("\\;", ";").replaceAll("\\|", "|").replaceAll("\\.", ".");
    }
    return result;
}

export const getArraySourceRequest = (specification: string): ArraySourceRequest => {
    for (const sourcedef of arraySourceMap) {
        const result = sourcedef.regex.exec(specification);
        if (result) {
            return { source: sourcedef.source, params: removeProtect(result.groups || {}) };
        }
    };
    return { source: null, params: {} };
}
