import { SharedState } from "../../../sharedState/SharedState";
import { mapMetadataToItem } from "../../../utils/source-item.utils";
import { ArraySource } from "../../ArraySource";


/***
 * Returns a list of all metadata representing files that include a specified path.
 * 
 * Params:
 *     path: The path to the file
 */
export class QueryUsedbySource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        const filters = { ...sharedState.filters };
        filters.text = null;
        filters.custom = {};
        delete filters.system.type;
        filters.includes = params.path;
        const searchHits = sharedState.site?.name ?
            await sharedState.backend.search(sharedState.site?.name, sharedState.organization,
                sharedState.configuration, filters) : [];
        return searchHits.map((hit, ix) => {
            return { state: sharedState, ...mapMetadataToItem(hit), position: ix + 1 };
        })
    }
}