import filter from "lodash/filter";
import find from "lodash/find";

import { Metadata, PropertiesModel, PropertyValue, ValidityPropertyModel } from "../models/metadata.model";
import { Site } from "../models/site.model";
import { QueryFormat } from "./QueryFormat";
import { LocalizedTerm } from "../models/terms.model";
import { Settings } from "../models/settings.model";
import { SourceItem } from "../models/source-item.model";
import { FilterModel } from "../models/filter.model";
import { getLocationItem } from "../source/arraysource/location/LocationItemSource";
import { Backend } from "../client/backend";
import { ApiBackend } from "../client/backend.api";

/***
 * These keys are used in the URL query string to represent the state of the app.
 */
export const urlQueryKeys = {
    customProperties: "c",
    systemProperties: "s",
    textFilter: "t",
    validity: "v",
    diffBase: "b",
    displayProperties: "d",
    sortOrder: "o",
    organization: "g",
    configuration: "n",
}

export const cookieSettingsCookies = {
    accepted: "scdp.cookie-settings-accepted",
    functional: "scdp.cookie-settings-functional",
    performance: "scdp.cookie-settings-performance",
    analytics: "scdp.cookie-settings-analytics",
}

export interface DbQuerySpec {
    excludeEdition?: boolean;
    excludeTextFilter?: boolean;
    excludeAllSystemProperties?: boolean;
    excludeSystemProperties?: Array<string>;
    includeSystemProperties?: Array<string>;
    excludeAllCustomProperties?: boolean;
    excludeCustomProperties?: Array<string>;
    includeCustomProperties?: Array<string>;
}

export interface Search {
    count: number;
    from: number;
    to: number;
    list: Array<Metadata>;
    loaded: boolean;
}

export interface CssClassRule {
    className: string,      // Css class name to switch on/off
    id: string,             // Apply to element with this id
    rule: string,           // Expression for condition on/off
}

export interface InjectElementRule {
    id: string,             // Apply to element with this id
    element: Element,       // Element to inject as parent of element with matching id
}

export interface CookieSettings {
    accepted?: boolean,      // User has accepted cookie settings
    functional?: boolean,    // Functional cookies accepted
    performance?: boolean,   // Performance cookies accepted
    analytics?: boolean,     // Analytics cookies accepted
}

export interface SearchOrder {
    propertyName: string,
    propertyType: string,
    direction: "1" | "-1", // ascending or descending
}

export interface SearchOptions {
    order: Array<SearchOrder>,
}

export interface Callback {
    func: (state: SharedState, args: { [key: string]: any }) => void,
    enabledFunc?: (state: SharedState, args: { [key: string]: any }) => boolean,
}

/***
 * The SharedState class contains the state of the app in the context of each component.
 * Some of the properties are reflected in the URL, and are valid in the entire app.
 * Other properties are loaded by some component, and only valid in the sub tree inside that
 * component's content.
 */
export class SharedState {
    backend: Backend;                       // Backend implementation
    urlPath: string | null;                 // (URL) path part of current url
    sitePath: string | null;                // (URL) path to the current site, as specified in the url
    site: Site | null;                      // (URL) current site
    metadata: Metadata | null;              // Current metadata
    filters: FilterModel;                   // (URL) current filters
    path: string | null;                    // path to the currently loaded file
    content: string | null;                 // content of the current file, as plain html
    baselineContent: string | null;         // content of the baseline file, as plain html
    defaultCustomProps: PropertiesModel;    // Indicates which custom properties that use the default value
    defaultSystemProps: PropertiesModel;    // Indicates which system properties that use the default value
    defaultValidity: Array<ValidityPropertyModel>;    // Indicates which validity properties that use the default value
    displayProperties: PropertiesModel;     // (URL) properties that are not saved to the server, only used for display options
    diffBase: Array<ValidityPropertyModel>; // (URL) validity base for displaying differences
    file: Document | null;                  // content of the currently loaded file
    terms: { [key: string]: LocalizedTerm };// set of localizable terms
    search: Search;                         // result set obtained by querying the server for file matching all filters
    cssClassRules: Array<CssClassRule>;     // Rule based manipulation of css classes for highlighting etc
    injectElementRules: Array<InjectElementRule>; // Id based injection of elements (mainly for adding components to SVG content)
    settings: Settings | null;              // Custom defaults definition
    cookieSettings: CookieSettings;         // Cookie settings saved as cookies
    tempCookieSettings: CookieSettings;     // Cookie settings saved as cookies
    callbacks: { [key: string]: Callback }; // Callbacks that can be used to communicate from a descendant component to an ancestor
    isValidForm: boolean;                   // Indicates if a form in the current state is valid
    defaultSearchOptions: Array<string>;    // Indicates which search options that use the default value
    storedItems: { [key: string]: SourceItem };  // Customizable stores containing source items
    storedArrays: { [key: string]: Array<SourceItem> }; // Customizable stores containing source arrays
    organization: string | null;            // (URL) organization
    configuration: string | null;           // (URL) configuration
    cart: Array<SourceItem>;                // (URL) download cart
    isClient: boolean;                      // Indicates if the app is running in the browser or on the server side
    isLocal: boolean;                       // Indicates if the app is running locally or connected to the server

    constructor(props?: { [key: string]: any }) {
        this.backend = props?.backend || new ApiBackend();
        this.urlPath = props?.urlPath || null;
        this.sitePath = props?.sitePath || null;
        this.site = props?.site || null;
        this.metadata = props?.metadata || null;
        this.path = props?.path || null;
        this.content = props?.content || null;
        this.baselineContent = props?.baselineContent || null;
        this.filters = {
            slot: props?.filters?.slot || null,
            custom: props?.filters?.custom || {},
            system: props?.filters?.system || {},
            validity: props?.filters?.validity || [],
            path: props?.filters?.path || null,
            text: props?.filters?.text || null,
            includes: props?.filters?.includes || null,
            searchOptions: props?.filters?.searchOptions || { order: [] },
            contentType: props?.filters?.contentType || null,
        };
        this.defaultCustomProps = props?.defaultCustomProps || {};
        this.defaultSystemProps = props?.defaultSystemProps || {};
        this.defaultValidity = props?.defaultValidity || [];
        this.displayProperties = props?.displayProperties || {};
        this.diffBase = props?.diffBase || [];
        this.file = props?.file || null;
        this.terms = props?.terms || {};
        this.search = props?.search || {
            count: 0,
            from: 0,
            to: 0,
            list: [],
            loaded: false,
        };
        this.cssClassRules = props?.cssClassRules || [];
        this.injectElementRules = props?.injectElementRules || [];
        this.settings = props?.settings || null;
        this.cookieSettings = props?.cookieSettings || {};
        this.tempCookieSettings = props?.tempCookieSettings || {};
        this.callbacks = props?.callbacks || {};
        this.isValidForm = props?.isValidForm || false;
        this.defaultSearchOptions = props?.defaultSearchOptions || [];
        this.storedItems = props?.storedItems || {};
        this.storedArrays = props?.storedArrays || {};
        this.organization = props?.organization || null;
        this.configuration = props?.configuration || null;
        this.cart = props?.cart || [];
        this.isClient = typeof window !== 'undefined';
        this.isLocal = this.isClient && window.location.protocol === "file:";
    }

    // A safe way to clone the SharedState, more performant that lodash cloneDeep
    public clone(): SharedState {
        return cloneState(this);
    }

    public updateFromQuery(urlQuery: string, settings: Settings | undefined | null): void {
        const parsedQuery: any = QueryFormat.parse(urlQuery.startsWith('?') ? urlQuery.substring(1) : urlQuery);
        if (this.urlPath && this.site) {
            this.sitePath = this.getSitePath(this.urlPath, this.site);
        }
        this.filters.text = parsedQuery.filters.text || "";
        this.filters.custom = parsedQuery.filters.custom || {};
        this.filters.searchOptions = parsedQuery.searchOptions || { order: [] };
        this.organization = parsedQuery.organization || null;
        this.configuration = parsedQuery.configuration || null;
        this.diffBase = parsedQuery.diffBase || [];
        this.displayProperties = parsedQuery.displayProperties || {};
        this.filters.system = parsedQuery.filters.system || {};
        this.filters.validity = parsedQuery.filters.validity || [];

        // Lang and validity are special cases, since they can be set to default values
        // const curLang = this.filters.system.lang;
        // const curValidity = this.filters.validity;
        // this.filters.system = parsedQuery.filters.system || {};
        // if (curLang && !this.filters.system.lang) {
        //     this.filters.system.lang = curLang;
        // }
        // this.filters.validity = parsedQuery.filters.validity || [];
        // if (curValidity && !this.filters.validity.length) {
        //     this.filters.validity = curValidity;
        // }

        if (settings) {
            this.settings = settings;

            // Look for preferred language in custom language mappings first
            if (!this.filters.system.lang?.length) {
                if (settings.defaults?.languageMappings) {
                    const hasWindow = typeof window !== 'undefined';
                    const languagePreferences = hasWindow && window.navigator.languages;
                    if (languagePreferences && languagePreferences.length) {
                        for (let pref of languagePreferences) {
                            if (settings.defaults.languageMappings[pref]) {
                                this.filters.system.lang = [settings.defaults.languageMappings[pref]];
                                this.defaultSystemProps.lang = [settings.defaults.languageMappings[pref]];
                                break;
                            }
                        }
                    }
                }
            }
            // Or fallback to common default language
            if (!this.filters.system.lang?.length) {
                if (settings.defaults?.language) {
                    this.filters.system.lang = [settings.defaults.language];
                    this.defaultSystemProps.lang = [settings.defaults.language];
                }
            }

            // Add defined default custom properties if needed
            if (settings.defaults?.customProperties) {
                for (let propName in settings.defaults.customProperties) {
                    if (!this.filters.custom[propName]) {
                        this.filters.custom[propName] = [settings.defaults.customProperties[propName]];
                        this.defaultCustomProps[propName] = [settings.defaults.customProperties[propName]];
                    }
                }
            }

            // Add defined validity system properties if needed
            if (settings.defaults?.validityProperties) {
                for (let propName in settings.defaults.validityProperties) {
                    // Look in dependency mappings first
                    if (!find(this.filters.validity, (v: ValidityPropertyModel) => { return v.name === propName })) {
                        if (settings.defaults.validityProperties[propName].dependencyMappings && settings.defaults.validityProperties[propName].dependency) {
                            const dependencyValue = find(this.filters.validity, (v: ValidityPropertyModel) => {
                                return !!settings.defaults?.validityProperties && v.name === settings.defaults?.validityProperties[propName].dependency
                            })?.value;
                            if (dependencyValue) {
                                const depmap = settings.defaults.validityProperties[propName].dependencyMappings;
                                if (depmap) {
                                    for (let val in depmap) {
                                        if (val === dependencyValue) {
                                            this.filters.validity.push({ name: propName, value: depmap[val] });
                                            this.defaultValidity.push({ name: propName, value: depmap[val] });
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (!find(this.filters.validity, (v: ValidityPropertyModel) => { return v.name === propName })) {
                        this.filters.validity.push({ name: propName, value: settings.defaults.validityProperties[propName].default });
                        this.defaultValidity.push({ name: propName, value: settings.defaults.validityProperties[propName].default });
                    }
                }
            }

        }

        // Add location item to state
        this.storedItems["_location"] = getLocationItem(this);
    }

    getSitePath(urlPath: string, site: Site): string {
        return urlPath.startsWith(`/${site.name}`) ? `/${site.name}` : '';
    }

    public getUrl(): string {
        return `${this.urlPath}?${this.getUrlQuery()}`;
    }

    public getUrlQuery(): string {
        return QueryFormat.stringify(this);
    }

    public setOneValidity(name: string, value: PropertyValue | null) {
        this.filters.validity = filter(this.filters.validity, valprop => { return valprop.name !== name });
        if (value) {
            this.filters.validity.push({ name: name, value: value })
        }
    }

    public setOneBaseline(name: string, value: PropertyValue | null) {
        this.diffBase = filter(this.diffBase, valprop => { return valprop.name !== name });
        if (value) {
            this.diffBase.push({ name: name, value: value });
        }
    }

    public clearOneValidity(name: string) {
        this.filters.validity = filter(this.filters.validity, valprop => { return valprop.name !== name });
    }

    public clearOneBaseline(name: string) {
        this.diffBase = filter(this.diffBase, valprop => { return valprop.name !== name });
    }


    public getValidityValues(name: string): Array<PropertyValue> {
        const found = find(this.filters.validity, { name: name });
        return found ? [found.value] : [];
    }

    public getBaselineValues(name: string): Array<PropertyValue> {
        const found = find(this.diffBase, { name: name });
        return found ? [found.value] : [];
    }
}

/***
 * Clone a SharedState object.
 * Can also be used to clone a state object from the server, which is not an
 * instance of SharedState.
 */
export function cloneState(s: any): SharedState {
    const c: SharedState = Object.assign(new SharedState(), s);
    c.backend = s.backend;
    c.metadata && (c.metadata = Object.assign({}, s.metadata));
    c.filters = {
        slot: s.filters.slot,
        custom: Object.assign({}, s.filters.custom),
        system: Object.assign({}, s.filters.system),
        validity: s.filters.validity ? [...s.filters.validity] : [],
        path: s.filters.path,
        text: s.filters.text,
        includes: s.filters.includes,
        searchOptions: Object.assign({}, s.filters.searchOptions),
        contentType: s.filters.contentType,
    }
    c.defaultCustomProps = Object.assign({}, s.defaultCustomProps);
    c.defaultSystemProps = Object.assign({}, s.defaultSystemProps);
    c.defaultValidity = [...s.defaultValidity];
    c.displayProperties = Object.assign({}, s.displayProperties);
    c.diffBase = [...s.diffBase];
    c.terms = Object.assign({}, s.terms);
    c.settings = s.settings ? Object.assign({}, s.settings) : null;
    c.search = Object.assign({}, s.search);
    c.search.list = [...s.search.list];
    c.cssClassRules = [...s.cssClassRules];
    c.injectElementRules = [...s.injectElementRules];
    c.cookieSettings = Object.assign({}, s.cookieSettings);
    c.tempCookieSettings = Object.assign({}, s.tempCookieSettings);
    c.callbacks = Object.assign({}, s.callbacks);
    c.defaultSearchOptions = [...s.defaultSearchOptions];
    c.storedItems = Object.assign({}, s.storedItems);
    c.storedArrays = Object.assign({}, s.storedArrays);
    c.cart = [...s.cart];
    return c;
}
