import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { ValueModifier } from "../ValueModifier";

/***
 * Replaces the value with a label, defined in settings.
 * 
 *  Params:
 *          type: Type of label, e.g. "language"
 */
export class LabelValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) {
        switch (params.type) {
            case "language":
                return sharedState.settings?.labels?.languages?.[value?.toString() || ""] || value?.toString() || "";
            default:
                return value?.toString() || "";
        }
    }
}