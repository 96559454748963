import { memo } from 'react';
import { buildContent } from '../component-lists/helpers';
import { SharedState } from '../sharedState/SharedState';
import { resolveValue, stateModifierRegex } from '../source/Source';
import { getStateChangerRequest } from '../source/StateModifier';

/***
 * A component that modifies the sharedState.
 * The specification must contain one or more state modifiers, which are run in the order they appear.
 */
export const ModifyState = memo(function ModifyState(props: {
  sharedState: SharedState,
  specification: string,
  content: Array<Node>,
}) {

  let nextState = props.sharedState.clone();
  let str = props.specification || "";
  let stateModifierMatch;
  while ((stateModifierMatch = stateModifierRegex.exec(str || "")) !== null) {
    runStateModifier(nextState, stateModifierMatch[0].slice(1, stateModifierMatch[0].length - 1));
    str = str.slice(0, stateModifierMatch.index) + str.slice(stateModifierMatch.index + stateModifierMatch[0].length)
  }
  if (str) {
    nextState = runStateModifier(nextState, str);
  }

  return <>{buildContent(nextState, props.content, "StoreItem", [])}</>;
});

function runStateModifier(state: SharedState, specification: string) {
  const stateModifierRequest = getStateChangerRequest(resolveValue(state, specification, true));
  let nextState = null;
  if (stateModifierRequest.modifier) {
    nextState = stateModifierRequest.modifier.modify(state, stateModifierRequest.params);
  }
  return nextState ? nextState : state;
}