import { PropertyValue } from "../../../models/metadata.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ValueSource } from "../../ValueSource";

/***
 * Returns the first value from a property in a stored item.
 * This convenience method can be used when there is only one value in a property.
 * 
 * Params:
 *      store: The name of the store
 *      type: property category
 *      name: The name of the value to return
 */
export class StorePropertyValueSource implements ValueSource {
    public getValue(sharedState: SharedState, params: { [name: string]: string }): PropertyValue {
        const props = sharedState.storedItems[params.store]?.props[params.type];
        if (props && props[params.name] && props[params.name].length) {
            return props[params.name][0];
        }
        return "";
    };
}