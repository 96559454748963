import isEqual from "lodash/isEqual"
import map from "lodash/map"
import React, { memo, useEffect, useState } from 'react';
import { SharedState } from "../sharedState/SharedState";
import { Autocomplete, TextField } from "@mui/material";
import { PropertyValue } from "../models/metadata.model";
import { useNavigate } from "react-router-dom";
import { loadSourceArray, resolveValue } from "../source/Source";
import { SourceItem } from "../models/source-item.model";


/***
 * A selector component that allows the user to select a value from a list of values.
 * The list of values is loaded using an ArraySource specification.
 */
export const Selector = memo(function Selector(props: {
  sharedState: SharedState,
  specification: string,
  persist: string,
  default: string,
  presentation: string,
  label: string,
  autocompleteProps: any,
  textfieldProps: any,
  redirect: String,
}) {

  const useNavigateOnClient = props.sharedState.isClient ? useNavigate : () => () => { };
  /**
   * Use history to change the search query by updating the page URL
   */
  const navigate = useNavigateOnClient();

  /**
   * Valueset is a list of all possible values for the property based on the current search
   * query. It is loaded using an ArraySource specification.
   */
  const [valueset, setValueset] = useState<Array<SourceItem>>([]);

  const [labels, setLabels] = useState<{ [key: string]: string }>({});

  /**
   * Open/close state for the autocomplete component
   */
  const [open, setOpen] = useState<boolean>(false);

  /**
   * The selected value(s)
   */
  const [value, setValue] = React.useState<Array<SourceItem>>([]);

  const multiple: boolean = props.autocompleteProps?.multiple || false;
  const persistSeparatorIx = props.persist?.indexOf(".") || -1;
  const persistType: string = persistSeparatorIx > 0 ? props.persist.slice(0, persistSeparatorIx) : "";
  const persistName: string = persistSeparatorIx > 0 ? props.persist.slice(persistSeparatorIx + 1) : "";

  /***
   * Load the data using an ArraySource specification
   */
  useEffect(() => {
    if (props.sharedState.isClient) {
      let active = true;

      loadSourceArray(props.sharedState, props.specification).then(async (vs) => {
        const labels: { [key: string]: string } = {};
        for (let item of vs) {
          if (props.presentation) {
            const presentationState = props.sharedState.clone();
            presentationState.storedItems["_selector_label"] = item;
            labels[item.value.toString()] = resolveValue(presentationState, props.presentation, false) || "";
          }
          else {
            labels[item.value.toString()] = item.value.toString() || "";
          }
        }
        // Make sure current values are included in the list of values
        for (let val of value) {
          if (!vs.find(item => item.value === val.value)) {
            vs.push(val);
            labels[val.value.toString()] = val.value.toString();
          }
        }

        active && setLabels(labels);
        active && setValueset(vs);
      });
      return () => {
        active = false;
      }
    }
  }, [props.specification, props.sharedState, props.presentation, value]);


  // Load current values
  let stateValues: Array<PropertyValue>;
  switch (persistType) {
    case "custom":
      stateValues = props.sharedState.filters.custom[persistName] || [];
      break;
    case "system":
      stateValues = props.sharedState.filters.system[persistName] || [];
      break;
    case "validity":
      stateValues = props.sharedState.getValidityValues(persistName);
      break;
    case "baseline":
      stateValues = props.sharedState.getBaselineValues(persistName);
      break;
    default:
      stateValues = [];
      break;
  }

  const newValue: Array<SourceItem> = map(stateValues, (val) => {
    const valueDef = valueset.find((item) => item.value === val);
    return valueDef || { value: val, info: 0, props: {} };
    // return { value: val, info: valueDef ? valueDef.info : 0 }
  });
  if (!isEqual(value, newValue)) {
    setValue(newValue);
  }

  // Callback when the value is changed by this component
  const changeValue = (changedValue: Array<PropertyValue>): void => {
    const nextState = props.sharedState.clone();
    let doHardRedirect = false;
    if (props.redirect) {
      // Use browser navigation to update the URL instead of React Router if there is a redirect
      // to another page. This way the new page will get loaded from the server.      
      nextState.urlPath = `${nextState.sitePath}${props.redirect}`;
      if (nextState.urlPath !== props.sharedState.urlPath) {
        doHardRedirect = true;
      }
      // If the user does a search, remove the help id filter so they can search in the whole site
      delete nextState.filters.system["hid"];
    }

    switch (persistType) {
      case "custom":
        if (changedValue.length) {
          nextState.filters.custom[persistName] = changedValue;
        }
        else {
          delete nextState.filters.custom[persistName];
        }
        break;

      case "validity":
        nextState.setOneValidity(persistName, changedValue.length ? changedValue[0] : null);
        // Also clear value of dependent validity
        if (props.sharedState.settings?.defaults?.validityProperties) {
          for (let valProp in props.sharedState.settings.defaults.validityProperties) {
            const def = props.sharedState.settings.defaults.validityProperties[valProp];
            if (def.dependency === persistName) {
              nextState.clearOneValidity(valProp);
            }
          }
        }
        doHardRedirect = true; // Changing validity may change the page
        break;

      case "baseline":
        nextState.setOneBaseline(persistName, changedValue.length ? changedValue[0] : null);
        doHardRedirect = true;
        break;

      case "system":
        if (changedValue.length) {
          nextState.filters.system[persistName] = changedValue;
        }
        else {
          delete nextState.filters.system[persistName];
        }
        doHardRedirect = true; // Changing system filter such as language may change the page
        break;
    }

    doHardRedirect ? window?.location?.replace(nextState.getUrl()) : navigate(nextState.getUrl());
  }

  const getOptionLabel = (option: SourceItem) => {
    return labels[option.value.toString()] || "";
  }

  if (valueset) {
    const textfieldProps = props.textfieldProps || {};
    textfieldProps.label = resolveValue(props.sharedState, props.label || textfieldProps.label || "", false);
    return <Autocomplete {...props.autocompleteProps}
      open={open}
      disablePortal={true}
      onOpen={async () => { setOpen(true) }}
      onClose={() => { setOpen(false) }}
      options={valueset}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option: SourceItem, val: SourceItem) => option.value === val.value}
      value={(multiple ? value : value[0]) || null}
      onChange={(event: React.SyntheticEvent<Element, Event>, newVal: any) => {
        if (multiple) {
          const values: Array<PropertyValue> = map(newVal as [SourceItem], val => val.value);
          changeValue(values);
        }
        else {
          changeValue(newVal ? [(newVal as SourceItem).value] : []);
        }
      }}
      renderInput={(inputParams: any) => {
        return <TextField {...inputParams} {...textfieldProps} />
      }}
    />;
  }
  else {
    return <></>;
  }
});