import { PropertyValue } from "../../../models/metadata.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ValueSource } from "../../ValueSource";

/***
 * Returns a value from a stored item.
 * This is primarily meant for use in test cases.
 * 
 * Params:
 *      type: The filter type
 *      name: The filter property name
 */
export class FilterValueSource implements ValueSource {

    public getValue(sharedState: SharedState, params: { [name: string]: string }): PropertyValue {
        switch (params.type) {
            case "custom":
                return sharedState.filters.custom[params.name || ""]?.[0] || "";
            case "system":
                return sharedState.filters.system[params.name || ""]?.[0] || "";
        }
        return "";
    };
}