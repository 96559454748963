import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns a list of all property names of a certain category in a source item.
 * 
 * Params:
 *      store: the name of the store to load validity combos from
 *      properties: the properties to use for the matrix, colon-separated
 */
export class ValidityMatrixSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }): Promise<Array<SourceItem>> {
        const properties = params.properties.split(":");
        const result: Array<SourceItem> = [];
        const combos = [...sharedState.storedItems?.[params.store]?.combos || []];
        if (combos) {
            // Sort the combos by the properties
            combos.sort((a, b) => {
                for (const property of properties) {
                    const avalue = a.combo?.find((valprop) => { return valprop.name === property; })?.value || "";
                    const bvalue = b.combo?.find((valprop) => { return valprop.name === property; })?.value || "";
                    if (avalue < bvalue)
                        return -1;
                    if (avalue > bvalue)
                        return 1;
                }
                return 0;
            });

            // Create the matrix
            let prev: { [key: string]: SourceItem } = {};
            for (let combo of combos) {
                const current: { [key: string]: SourceItem } = {};
                let found = false;
                let ix: number = 1;
                let isRepeat = true;
                for (const property of properties) {
                    const value = combo.combo.find((valprop) => {
                        return valprop.name === property;
                    })?.value?.toString() || "";
                    isRepeat = !!value && isRepeat && prev[property]?.value === value;
                    const item: SourceItem = {
                        value: value,
                        props: {
                            info: {
                                "name": [property],
                                "isrepeat": isRepeat ? [true] : [false],
                            }
                        },
                        state: sharedState,
                        position: ix++,
                    };
                    current[property] = item;
                    if (value) {
                        found = true;
                    }
                }
                // Only include combos that contain at least one of the sought properties
                ix = 1;
                if (found) {
                    result.push({
                        value: "",
                        props: {},
                        children: Object.values(current),
                        state: sharedState,
                        position: ix++,
                    });
                }
                prev = current;
            }
        }
        return result;
    }
}