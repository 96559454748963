import { useEffect, useState, ChangeEvent, MouseEvent, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { buildNode } from '../component-lists/helpers';
import { PropertyValue } from '../models/metadata.model';
import { SharedState } from "../sharedState/SharedState";

/***
 * A UI control that determines the value of a display property.
 * Display properties are properties that are added to the URL query and the sharedState
 * but that do not correspond to any properties in the metadata model.
 * It can be used for example to show/hide or highlight a part of the page based on a user action.
 */
export const DisplayValueControl = memo(function DisplayValueControl(props: {
  sharedState: SharedState,
  name: string,
  startTrue: boolean,
  content: Array<Node>
}): JSX.Element {

  const useNavigateOnClient = props.sharedState.isClient ? useNavigate : () => () => { };
  const navigate = useNavigateOnClient();

  const [value, setValue] = useState<PropertyValue>("");

  /***
   * Recalculate value on every change to sharedstate
   */
  useEffect(() => {
    setValue((props.sharedState.displayProperties[props.name] && props.sharedState.displayProperties[props.name][0]) || "");
  }, [props.sharedState, props.name])


  let checkbox: Node | null = null;
  let input: Node | null = null;
  let mouseoverTarget: Node | null = null;
  for (let n of props.content) {
    if (n.nodeType === 1) { // Node.ELEMENT_NODE
      if (n.nodeName.toLowerCase() === "checkbox") {
        checkbox = n;
        break;
      }
      else if (n.nodeName.toLowerCase() === "input") {
        input = n;
        break;
      }
      else {
        mouseoverTarget = n;
      }
    }
  }

  const updateValue = (changedValue: string) => {
    const nextSharedState = props.sharedState.clone();
    if (changedValue) {
      nextSharedState.displayProperties[props.name] = [changedValue];
    }
    else {
      delete nextSharedState.displayProperties[props.name];
    }
    navigate(nextSharedState.getUrl());
  }

  if ((checkbox || input) && props.startTrue && !props.sharedState.displayProperties[props.name]) {
    updateValue("true");
  }

  let content: JSX.Element | string = "";

  if (input) {
    const childProps = {
      "onChange": (event: ChangeEvent<HTMLSelectElement>) => { updateValue((value && value !== "false") ? (props.startTrue ? "false" : "") : "true"); },
      "checked": (value && value !== "false") || false,
    }
    content = buildNode(props.sharedState, input, "0", childProps, null, false);
  }
  else if (checkbox) {
    const childProps = {
      "onChange": (event: ChangeEvent<HTMLSelectElement>) => { updateValue((value && value !== "false") ? (props.startTrue ? "false" : "") : "true") },
      "checked": (value && value !== "false") || false,
    }
    content = buildNode(props.sharedState, checkbox, "0", childProps, null, false);
  }
  else if (mouseoverTarget) {
    const childProps = {
      "onMouseOver": (event: MouseEvent) => { updateValue("true") },
      "onMouseOut": (event: MouseEvent) => { updateValue("") },
    }
    content = buildNode(props.sharedState, mouseoverTarget, "0", childProps, null, false);
  }
  else {
    content = <div className="error display-value-control missing-child-element" />;
  }

  return <>{content}</>;
});