import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns all children of a stored item.
 * 
 *      store: The name of the store
 */
export class ChildrenArraySource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        return sharedState.storedItems[params.store || ""]?.children || [];
    }
}