import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { ValueModifier } from "../ValueModifier";

/***
 * Localizes a date value.
 * 
 * Params:
 *          lang: localize to a particular language, may be omitted to use the current language
 */
export class LocalizeDateValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) {
        if (value instanceof Date || typeof value === "number") {
            const lang = params.lang || (sharedState.filters.system.lang ? sharedState.filters.system.lang[0].toString() : "");
            if (lang) {
                const date: Date = new Date(value);
                return Intl.DateTimeFormat(lang).format(date);
            }
        }
        return value?.toString() || "";
    }
}