/***
 * Constant sources echo information entered in the specification.
 */

import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns an array of strings in the specification, translformed into SourceItems.
 * 
 * Params:
 *    values: The array values, separated by colon
 */
export class ConstantSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }): Promise<Array<SourceItem>> {
        const array: Array<string> = params.values?.split(":") || [];
        return array.map((value, ix) => {
            return { state: sharedState, value: value, props: {}, position: ix + 1 };
        });
    }
}
