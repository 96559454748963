/***
 * Value sources are used to retrieve values from the shared state.
 * They are usually invoked by using the $ prefix, followed by a specification, and terminated by a semicolon.
 */

import { PropertyValue } from "../models/metadata.model";
import { SharedState } from "../sharedState/SharedState";
import { ConstantValueSource } from "./valuesource/ConstantValueSource";
import { FilterValueSource } from "./valuesource/filter/FilterValueSource";
import { StorePropertyValueSource } from "./valuesource/store/StorePropertyValueSource";
import { StoreValueSource } from "./valuesource/store/StoreValueSource";

export type ValueSource = {
    getValue: (sharedState: SharedState, params: { [name: string]: string; }) => PropertyValue;
};

export type ValueSourceRequest = {
    source: ValueSource | null;
    params: { [name: string]: string; };
};

export const valueSourceMap: Array<{ source: ValueSource, regex: RegExp }> = [


    {
        source: new ConstantValueSource(),
        regex: /constant\.(?<value>.+)/,
    },
    {
        source: new FilterValueSource(),
        regex: /filter\.(?<type>.+)\.(?<name>.+)/,
    },

    /***
     * Store source
     */
    {
        source: new StorePropertyValueSource(),
        regex: /(?<store>.+)\.(?<type>.+)\.(?<name>.+)/,
    },
    {
        source: new StoreValueSource(),
        regex: /(?<store>.+)\.(?<property>.+)/,
    },


];

function removeProtect(params: { [key: string]: string }): { [key: string]: string } {
    const result: { [key: string]: string } = {};
    for (const key in params) {
        result[key] = params[key]?.replaceAll("\\$", "$").replaceAll("\\;", ";").replaceAll("\\|", "|").replaceAll("\\.", ".");
    }
    return result;
}

export const getValueSourceRequest = (specification: string): ValueSourceRequest => {
    for (const sourcedef of valueSourceMap) {
        const result = sourcedef.regex.exec(specification);
        if (result) {
            return { source: sourcedef.source, params: removeProtect(result.groups || {}) };
        }
    };
    return { source: null, params: {} };
}
