/***
 * Array filters are used to transform arrays before they are used.
 */

import { SourceItem } from "../models/source-item.model";
import { SharedState } from "../sharedState/SharedState";
import { DebugArrayModifier } from "./arraymodifier/DebugArrayModifier";
import { FilterArrayModifier } from "./arraymodifier/FilterArrayModifier";
import { SortArrayModifier } from "./arraymodifier/SortArrayModifier";

export type ArrayModifier = {
    modify: (sharedState: SharedState, array: Array<SourceItem>, params: { [name: string]: string }) => Array<SourceItem>;
}

export type ArrayModifierRequest = {
    modifier: ArrayModifier | null;
    params: { [name: string]: string };
}

export const arrayModifierMap: Array<{ modifier: ArrayModifier, regex: RegExp }> = [
    {
        modifier: new DebugArrayModifier(),
        regex: /debug\.(?<message>.+)/,
    },
    {
        modifier: new SortArrayModifier(),
        regex: /sort\.(?<order>.+)/,
    },
    {
        modifier: new FilterArrayModifier(),
        regex: /filter\.(?<remove>.+)/,
    },
]

export const getArrayModifierRequest = (specification: string): ArrayModifierRequest => {
    for (const def of arrayModifierMap) {
        const result = def.regex.exec(specification);
        if (result) {
            return { modifier: def.modifier, params: result.groups || {} };
        }
    };
    return { modifier: null, params: {} };
}
