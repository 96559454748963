import { SharedState } from '../sharedState/SharedState';
import { memo } from "react";
import { buildNode } from "../component-lists/helpers";
import { findRoot, getDOMParser } from '../utils/dom.utils';
import { compareDom } from '../diff/dom-compare';

/***
 * This component marks the place where the content of the current page is rendered.
 * This is needed because there is a wrapping file that contains things like page header,
 * and the content of the page is rendered inside that wrapper.
 */
export const PageLoader = memo(function PageRouter(props: { sharedState: SharedState }) {
    let displayContent: string | JSX.Element = <></>;
    if (props.sharedState.content) {
        let doc: Document = getDOMParser().parseFromString(props.sharedState.content || "", "text/html");
        let docRoot: Element | null = findRoot(doc);
        if (docRoot) {
            if (props.sharedState.baselineContent) {
                const baselineDoc: Document = getDOMParser().parseFromString(props.sharedState.baselineContent || "", "text/html");
                const baselineDocRoot: Element | null = findRoot(baselineDoc);
                if (baselineDocRoot) {
                    // Compare function modifies the DOM in docRoot, so we need to clone it
                    doc = doc.cloneNode(true) as Document;
                    const clonedDocRoot: Element | null = findRoot(doc);
                    if (clonedDocRoot) {
                        docRoot = clonedDocRoot;
                        compareDom(baselineDocRoot, docRoot, 5000);
                    }
                }
            }
            displayContent = buildNode(props.sharedState, docRoot, "PageLoader", {}, null, false);
        }
    }
    return <>{displayContent}</>;
});