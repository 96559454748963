import { Dialog } from "@mui/material";
import { memo, useState } from "react";
import { SharedState } from "../sharedState/SharedState";
import { RenderPath } from "./RenderPath";

/***
 * A button that opens a dialog.
 * The path is the path to the dialog content.
 */
export const OpenDialogButton = memo(function OpenDialogButton(props: {
  sharedState: SharedState,
  path: string,
  label: string,
}) {

  const [open, setOpen] = useState<boolean>(false);

  props.sharedState.callbacks["close-dialog"] = { func: () => { setOpen(false) } };

  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  }

  return <>
    <button onClick={clickHandler}>{props.label}</ button>
    <Dialog open={open}>
      <RenderPath sharedState={props.sharedState} path={props.path} ismain={false} />
    </Dialog>
  </>
});