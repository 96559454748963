// the LCS matrix will be max 20000 * 20000 * 2 bytes = 80 mb

import { getFirstElementChild } from "../utils/dom.utils";
import { BacktrackItem, SequenceItem, SequenceItemType, SequenceStarttagItem } from "./sequence-item";

// This constant can not be set higher than 65535 since we use uint16 values on the LCS matrix
// const maxSequenceLength = 20000;

export interface compareInfo {
    runs: Array<{
        maxDepth: number,
        baselineSequenceLength: number,
        currentSequenceLength: number,
        lcsLength: number,
        lcsFullLength: number,
        matchedElements: number,
        time: number,
    }>,
}

/***
 * NOTE: This method can only be used from browser environment, as it relies on the DOM interface.
 * 
 * Compares two DOM trees and returns a diff.
 * The diff is stored in the current DOM tree as attributes on the elements.
 * 
 * The algorithm works by first creating sequences of nodes in each of the DOM trees. Initially, the sequences
 * only contain the root nodes. The sequences are then compared in iterations. In each iteration, the sequences
 * are expanded by adding child nodes to the sequence where the parent nodes did not match.
 * In each iteration, the sequences are compared using the LCS algorithm.
 * The process ends when all nodes have been matched or expanded to the lowest leaf, or when the maximum sequence
 * length has been reached.
 * 
 * The smallest unit that can be marked as a difference is a word. Words are delimited by space. Since thw original
 * DOM tree does not contain any information about word boundaries, the algorithm will add display nodes for words
 * into the DOM tree. This means that the DOM tree will be modified by the algorithm.
 * 
 * The maximum sequence length is used to limit the amount of memory used by the algorithm. The algorithm will
 * not expand sequences beyond the maximum sequence length. The value should be set to a value that is high enough
 * to cover most cases, but low enough to avoid using too much memory.
 * 
 *  
 * @param baselineRoot the root of the baseline DOM tree
 * @param currentRoot the root of the current DOM tree
 * @param maxSequenceLength the maximum length of the sequence that will be expanded
 */
export function compareDom(baselineRoot: Element, currentRoot: Element, maxSequenceLength: number): compareInfo {
    let maxDepth = 1;
    let keepGoing = true;

    prepareDom(baselineRoot);
    prepareDom(currentRoot);

    let baselineSequence: Array<SequenceItem> = createSequence(baselineRoot);
    let currentSequence: Array<SequenceItem> = createSequence(currentRoot);
    let backtrackSequence: Array<BacktrackItem> = [];

    const info: compareInfo = { runs: [] };

    while (keepGoing) {
        const t0: number = Date.now();
        const baselineCanGoDeeper = expandSequence(maxDepth, maxSequenceLength, baselineSequence);
        const currentCanGoDeeper = expandSequence(maxDepth, maxSequenceLength, currentSequence);
        if (info.runs.length) {
            const previousRun = info.runs[info.runs.length - 1];
            if (previousRun.baselineSequenceLength === baselineSequence.length && previousRun.currentSequenceLength === currentSequence.length) {
                break;
            }
        }
        keepGoing = baselineCanGoDeeper || currentCanGoDeeper;
        const runInfo = {
            maxDepth: maxDepth,
            baselineSequenceLength: baselineSequence.length,
            currentSequenceLength: currentSequence.length,
            lcsLength: 0,
            lcsFullLength: 0,
            matchedElements: 0,
            time: 0,
        };
        info.runs.push(runInfo);
        const matrix: Uint32Matrix = longestCommonSubsequence(baselineSequence, currentSequence);
        runInfo.lcsFullLength = matrix.getCell(baselineSequence.length, currentSequence.length);
        backtrackSequence = runInfo.lcsFullLength ? backtrack(matrix, baselineSequence, currentSequence) : [];
        runInfo.lcsLength = backtrackSequence.length;
        runInfo.matchedElements = setMatchAttribute(backtrackSequence);
        runInfo.time = Date.now() - t0;
        maxDepth++;
    }

    // console.log("BASELINE", baselineSequence);
    // console.log("CURRENT", currentSequence);
    // console.log("BACKTRACK", backtrackSequence);

    buildDiffDisplayTree(currentRoot, backtrackSequence, baselineSequence, currentSequence);
    postProcessDom(currentRoot);
    return info;
}

function buildDiffDisplayTree(currentRoot: Element, backtrackSequence: Array<BacktrackItem>, baselineSequence: Array<SequenceItem>, currentSequence: Array<SequenceItem>): void {

    // Remove old content so we can build up a new tree with both inserted and deleted content
    while (currentRoot.lastChild) {
        currentRoot.removeChild(currentRoot.lastChild);
    }

    // Set start point to 1 instead of 0, because we don't want to insert the root node (it's already in the tree)
    let backtrackIx = 1;
    let baselineIx = 1;
    let currentIx = 1;
    let ancestors: Array<Element> = [currentRoot];

    while (backtrackIx < backtrackSequence.length || baselineIx < baselineSequence.length || currentIx < currentSequence.length) {
        let deletedItem: SequenceItem | null = null;
        let insertedItem: SequenceItem | null = null;
        if (baselineIx < baselineSequence.length && (backtrackIx === backtrackSequence.length || backtrackSequence[backtrackIx].baselineItem !== baselineSequence[baselineIx])) {
            deletedItem = baselineSequence[baselineIx];
        }
        if (currentIx < currentSequence.length && (backtrackIx === backtrackSequence.length || backtrackSequence[backtrackIx].currentItem !== currentSequence[currentIx])) {
            insertedItem = currentSequence[currentIx];
        }
        if (deletedItem && insertedItem) {
            // Select which one to pick
            if (insertedItem.itemType === SequenceItemType.WORD) {
                deletedItem = null;
            }
            else {
                insertedItem = null;
            }
        }
        if (deletedItem) {
            deletedItem.addDisplayNode(ancestors, true, false);
            baselineIx++;
        }
        else if (insertedItem) {
            insertedItem.addDisplayNode(ancestors, false, true);
            currentIx++;
        }
        else {
            // Unmodified sequence item (we could pick either current or baseline here)
            currentSequence[currentIx].addDisplayNode(ancestors, false, false);
            currentIx++;
            baselineIx++;
            backtrackIx++;
        }
    }

}

/***
 * Add precalculated attributes on the elements for optimization
 */
function prepareDom(element: Element): { html: string, count: number } {
    const result: { html: string, count: number } = {
        html: "",
        count: 0,
    }
    const htmlArray: Array<string> = [element.nodeName];
    let child: Node | null = element.firstChild;
    while (child) {
        if (child.nodeType === 3) { // Node.TEXT_NODE
            if (child.nodeValue) {
                const val = child.nodeValue.trim();
                if (val) {
                    htmlArray.push(val);
                    result.count += val.length + 2; // text node start + characters + text node end
                }
            }
        }
        else if (child.nodeType === 1 /* Node.ELEMENT_NODE */) {
            const childResult: { html: string, count: number } = prepareDom(child as Element);
            htmlArray.push(child.nodeName + childResult.html);
            result.count += childResult.count + 2; // start tag + child nodes + end tag
        }
        child = child.nextSibling;
    }
    const html: string = htmlArray.join("");
    result.html = html;
    element.setAttribute("data-diff-optimize-value", html);
    element.setAttribute("data-diff-optimize-hash", "" + hashCode(html));
    element.setAttribute("data-diff-optimize-count", "" + result.count);
    return result;
}

function postProcessDom(root: Element | null): boolean {
    let node: Node | null = root;
    let isDiff = false;
    while (node) {
        const nextNode: Node | null = node.nextSibling;
        if (node.nodeType === 1) { // Node.ELEMENT_NODE
            const element = node as Element;
            element.hasAttribute("data-diff-optimize-value") && element.removeAttribute("data-diff-optimize-value");
            element.hasAttribute("data-diff-optimize-hash") && element.removeAttribute("data-diff-optimize-hash");
            element.hasAttribute("data-diff-optimize-count") && element.removeAttribute("data-diff-optimize-count");
            if (node.textContent) {
                const isChildDiff = postProcessDom(getFirstElementChild(element));
                isDiff = isDiff || isChildDiff || false;
                if (element.hasAttribute("data-diff")) {
                    if (isChildDiff) {
                        // Don't mark diff on ancestor if there are descendant diffs
                        element.removeAttribute("data-diff");
                    }
                    isDiff = true;
                }
            }
        }
        if (!node.textContent) {
            // Remove empty diff nodes
            if (node.nodeType === 3) {
                node.parentElement?.removeChild(node);
            }
            else if (node.nodeType === 1) {
                const element = node as Element;
                if (element.hasAttribute("data-diff")) {
                    node.parentElement?.removeChild(node);
                }
            }
        }

        node = nextNode;
    }

    return isDiff;
}

/***
 * Walks throughh the backtrack tree and marks matched nodes
 */
function setMatchAttribute(sequence: Array<BacktrackItem>): number {
    let count = 0;
    for (let item of sequence) {
        const baselineMatched: boolean = item.baselineItem.setMatched();
        const currentMatched: boolean = item.currentItem.setMatched();
        baselineMatched && currentMatched && count++;
    }
    return count;
}

function createSequence(root: Element): Array<SequenceItem> {
    return [new SequenceStarttagItem(root)];
}

/***
 * Expands unmatched nodes in a sequence and adds the next level of nodes.
 */
function expandSequence(maxDepth: number, maxSequenceLength: number, sequence: Array<SequenceItem>): boolean {
    let canGoDeeper = false;
    let depth: number = 1;
    for (let seqIx = 0; seqIx < sequence.length; seqIx++) {
        const item = sequence[seqIx];
        if (item.isExpandable() && sequence.length + item.expandSize() <= maxSequenceLength) {
            if (depth <= maxDepth) {
                sequence.splice(seqIx + 1, 0, ...item.expand())
            }
            else {
                canGoDeeper = true;
            }
        }
        depth += item.depthModifier();
    }
    return canGoDeeper && sequence.length < maxSequenceLength;
}

/***
 * Standard impementation of the LCS algorithm
 * Returns a matrix containing sequence lengths
 */
function longestCommonSubsequence(baselineSequence: Array<SequenceItem>, currentSequence: Array<SequenceItem>): Uint32Matrix {
    const matrix = new Uint32Matrix(currentSequence.length + 1, baselineSequence.length + 1);
    for (let x = 1; x < baselineSequence.length + 1; x++) {
        for (let y = 1; y < currentSequence.length + 1; y++) {
            if (baselineSequence[x - 1].isEqual(currentSequence[y - 1])) {
                // matrix.setCell(x, y, matrix.getCell(x - 1, y - 1) + 1);
                matrix.setCell(x, y, matrix.getCell(x - 1, y - 1) + baselineSequence[x - 1].subSequenceSize());
            } else {
                matrix.setCell(x, y, Math.max(matrix.getCell(x - 1, y), matrix.getCell(x, y - 1)));
            }
        }
    }
    return matrix;
}

/***
 * Backtrack LCS matrix to get the longest sequence
 * @param matrix the LCS matrix
 * @param baselineSequence sequence corresponding to the baseline version of the document
 * @param currentSequence sequence corresponding to the current version of the document
 * @param x the X coordinate in the matrix - call with sequence1.items.length as initial value
 * @param y the Y coordinate in the matrix - call with sequence2.items.length as initial value
 * @param items where the longest common sequence will end up
 */
function backtrack(matrix: Uint32Matrix, baselineSequence: Array<SequenceItem>, currentSequence: Array<SequenceItem>): Array<BacktrackItem> {
    const items: Array<BacktrackItem> = [];
    let x = baselineSequence.length;
    let y = currentSequence.length;
    while (x && y) {
        if (baselineSequence[x - 1].isEqual(currentSequence[y - 1])) {
            items.push({ baselineItem: baselineSequence[x - 1], currentItem: currentSequence[y - 1] });
            x--;
            y--;
        }
        else if (matrix.getCell(x, y - 1) > matrix.getCell(x - 1, y)) {
            y--;
        }
        else {
            x--;
        }
    }
    return items.reverse();
}

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 *
 * @param {string} s a string
 * @return {number} a hash code value for the given string.
 */
const hashCode = (s: string): number => {
    var h: number = 0, l: number = s.length, i: number = 0;
    if (l > 0) {
        while (i < l) {
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
        }
    }
    return h;
};

class Uint32Matrix extends Uint32Array {
    rows: number;
    cols: number;

    constructor(rows: number, cols: number) {
        super(new ArrayBuffer(rows * cols * 4));
        this.rows = rows;
        this.cols = cols;

        for (let i = 0; i < this.length; i++) {
            this[i] = 0;
        }
    }



    public getCell(x: number, y: number) {
        return this[x + y * this.cols];
    }

    public setCell(x: number, y: number, value: number) {
        this[x + y * this.cols] = value;
    }

    // public print() {
    //     let debug = "";
    //     debug += "=== MATRIX ===" + "\n";
    //     debug += "ROWS" + " " + this.rows + "\n";
    //     debug += "COLS" + " " + this.cols + "\n";
    //     debug += "Length" + " " + this.length + "\n";
    //     for (let y = 0; y < this.rows; y++) {
    //         let line = "";
    //         for (let x = 0; x < this.cols; x++) {
    //             line = line + this.getCell(x, y) + " ";
    //         }
    //         debug += line + "\n";
    //     }
    //     debug += "==============";
    //     console.log(debug);
    // }
}