import { Metadata } from "../../../models/metadata.model";
import { SharedState } from "../../../sharedState/SharedState";
import { mapMetadataToItem } from "../../../utils/source-item.utils";
import { ArraySource } from "../../ArraySource";

/***
 * Returns all metadata objects that match the current filter and the current path.
 * 
 * Params: (none)
 */
export class QueryCurrentMetadataSource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        const searchlist: Array<Metadata> = sharedState.site?.name ? await sharedState.backend.search(
            sharedState.site.name, sharedState.organization, sharedState.configuration,
            { ...sharedState.filters, path: sharedState.path }) : [];
        return searchlist.map((hit, ix) => {
            return { state: sharedState, ...mapMetadataToItem(hit), position: ix + 1 };
        });
    }
}