import { PropertiesModel, ValidityPropertyModel } from "../../../models/metadata.model";
import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns the current URL.
 * 
 * Params: (none)
 */
export class LocationItemSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        return [getLocationItem(sharedState)];
    };
}

function mapValidity(validity: Array<ValidityPropertyModel>): PropertiesModel {
    const result: PropertiesModel = {};
    validity.forEach((prop) => {
        result[prop.name] = [prop.value];
    });
    return result;
}

export function getLocationItem(sharedState: SharedState): SourceItem {
    const item: SourceItem = {
        state: sharedState.clone(),
        value: sharedState.urlPath || "",
        position: 1,
        props: {
            other: {
                text: [sharedState.filters.text || ""],
                contentType: [sharedState.filters.contentType || ""],
                path: [sharedState.path || ""],
                site: [sharedState.site?.name || ""],
                sitePath: [sharedState.sitePath || ""],
                urlPath: [sharedState.urlPath || ""],
            },
            custom: sharedState.filters.custom,
            system: sharedState.filters.system,
            display: sharedState.displayProperties,
            validity: mapValidity(sharedState.filters.validity),
            baseline: mapValidity(sharedState.diffBase),
        },
    };
    if (sharedState.organization) {
        item.props.info = { organization: [sharedState.organization] };
    }
    if (sharedState.configuration) {
        item.props.info = { ...item.props.info, configuration: [sharedState.configuration] };
    }
    return item;
}