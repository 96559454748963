import { PropertyValue } from "../../models/metadata.model";
import { SourceItem } from "../../models/source-item.model";
import { SharedState } from "../../sharedState/SharedState";
import { ArrayModifier } from "../ArrayModifier";

/***
 * Sorts an array of SourceItems, based on the value field in the SourceItem.
 * 
 *  * Params:
 *          order: the order (asc or desc)
 */
export class SortArrayModifier implements ArrayModifier {
    public modify(sharedState: SharedState, array: Array<SourceItem>, params: { [name: string]: string }): Array<SourceItem> {
        const result = [...array];
        if (params.order === "ascending") {
            result.sort((a, b) => this.compare(a.value, b.value));
        }
        else if (params.order === "descending") {
            result.sort((a, b) => this.compare(b.value, a.value));
        }
        return result.map((item, ix) => { return { ...item, position: ix + 1 } });
    }

    private compare(a: PropertyValue, b: PropertyValue): number {
        if (a === b) {
            return 0;
        }
        else if (a === null) {
            return -1;
        }
        else if (b === null) {
            return 1;
        }
        else {
            return a.toString().localeCompare(b.toString());
        }
    }
}