import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { ValueSource } from "../ValueSource";

/***
 * Returns a constant value (for test and debugging purposes)
 * 
 * Params:
 *      value: The value
 */
export class ConstantValueSource implements ValueSource {
    public getValue(sharedState: SharedState, params: { [name: string]: string }): PropertyValue {
        return params.value;
    };
}