/***
 * This is the entry point for the client side of the application.
 */

import reportWebVitals from './reportWebVitals';
import { Site } from './models/site.model';
import { SiteRoot } from './components/SiteRoot';
import { SharedState, cloneState } from './sharedState/SharedState';
import { BrowserRouter } from 'react-router-dom';
import { hydrateRoot } from 'react-dom/client';
import createEmotionCache from './utils/emotion.utils';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import { LocalBackend } from './client/backend.local';
import { getDOMParser } from './utils/dom.utils';
import { ApiBackend } from './client/backend.api';

if (process.env.NODE_ENV === "development") {
  const serverUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:3000";
  const url: string = serverUrl + window.location.pathname + window.location.search;
  fetch(url, { method: "GET" }).then((response) => {
    response.text().then((text) => {
      const parser = getDOMParser();
      const parsedHtml = parser.parseFromString(text, 'text/html');
      const serverHead = parsedHtml.documentElement.querySelector("head");
      const serverBody = parsedHtml.documentElement.querySelector("body");
      if (serverHead && serverBody) {
        const importedHead = window.document.importNode(serverHead, true);
        const importedBody = window.document.importNode(serverBody, true);
        window.document.documentElement.replaceChild(importedHead, window.document.head);
        window.document.documentElement.replaceChild(importedBody, window.document.body);
        window.document.documentElement.innerHTML = parsedHtml.documentElement.innerHTML;
        const links: Array<HTMLLinkElement> = Array.from(window.document.head.querySelectorAll("link"));
        for (let link of links) {
          if (link.rel === "stylesheet") {
            const newLink = document.createElement("link");
            newLink.rel = "stylesheet";
            newLink.href = link.href;
            const appIndex = newLink.href.indexOf("/app/file/");
            if (appIndex !== -1) {
              newLink.href = serverUrl + "/app/file/" + newLink.href.slice(appIndex + 10);
            }
            window.document.head.appendChild(newLink);
            window.document.head.removeChild(link);
          }
        }
        hydratePage();
      }
    });
  });
}
else {
  hydratePage();
}

function hydratePage() {
  // Fetch site info from meta tags in head, where the server puts it
  const siteDef: Element | null = document?.head?.querySelector("[name=site]");
  const domainDef: Element | null = document?.head?.querySelector("[name=domain]");
  const titleDef: Element | null = document?.head?.querySelector("[name=title]");
  const descriptionDef: Element | null = document?.head?.querySelector("[name=description]");
  const indexcontentDef: Element | null = document?.head?.querySelector("[name=indexContent]");
  const stateDef: Element | null = document?.head?.querySelector("[name=state]");

  const sitename: string = (siteDef && siteDef.getAttribute("content")) || "";
  const domain: string = (domainDef && domainDef.getAttribute("content")) || "";
  const title: string = (titleDef && titleDef.getAttribute("content")) || "";
  const description: string = (descriptionDef && descriptionDef.getAttribute("content")) || "";
  const indexcontentB64 = (indexcontentDef && indexcontentDef.getAttribute("content")) || "";
  const stateB64 = (stateDef && stateDef.getAttribute("content")) || "";

  siteDef && siteDef.remove();
  domainDef && domainDef.remove();
  titleDef && titleDef.remove();
  descriptionDef && descriptionDef.remove();
  indexcontentDef && indexcontentDef.remove();
  stateDef && stateDef.remove();

  const indexcontent = decodeBase64(indexcontentB64);

  const state: SharedState = stateB64 ? new SharedState(JSON.parse(decodeBase64(stateB64))) : new SharedState();
  state.isClient = true;

  if (window.location.protocol === "file:") {
    state.isLocal = true;
    // When running locally (in downloaded package), the site path is the path to the root
    // of the zip archive.
    // The url path is the path to the current page within the site.
    // To get the site path, we need to remove the url path from the end of the url.
    const urlPathLevels: number = state.urlPath?.split("/").length || 0;
    const locationLevels: number = window.location.pathname.split("/").length || 0;
    state.sitePath = window.location.pathname.split("/", locationLevels - urlPathLevels + 1).join("/");
    state.urlPath = window.location.pathname;

    state.backend = new LocalBackend(state.path || "/");
  }
  else {
    state.backend = new ApiBackend();
  }

  const site: Site | null = sitename ? {
    _id: { str: "" },
    name: sitename,
    domain: domain,
    title: title,
    description: description,
  } : null;

  const cache = createEmotionCache();


  const root = document.getElementById('root');
  if (site && root) {
    hydrateRoot(root,
      <CacheProvider value={cache}>
        <ThemeProvider theme={{}}>
          <BrowserRouter>
            <SiteRoot indexcontent={indexcontent} state={cloneState(state)} />
          </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    );
  }
  else {
    <div>No site</div>
  }
}

function decodeBase64(s: string): string {
  return new TextDecoder().decode(Uint8Array.from(window.atob(s), (m) => m.charCodeAt(0)));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
