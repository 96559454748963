import { PropertyValue } from "../../../models/metadata.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ValueSource } from "../../ValueSource";

/***
 * Returns a value from a stored item.
 * 
 * Params:
 *      store: The name of the store
 *      property: The name of the value to return
 */
export class StoreValueSource implements ValueSource {

    public getValue(sharedState: SharedState, params: { [name: string]: string }): PropertyValue {
        switch (params.property) {
            case "length":
                return sharedState.storedArrays[params.store || ""]?.length || 0;
            case "value":
                return sharedState.storedItems[params.store || ""]?.value || "";
            case "position":
                return sharedState.storedItems[params.store || ""]?.position || 0;
            case "haschildren":
                return !!sharedState.storedItems[params.store || ""]?.children?.length || false;
        }
        return "";
    };
}