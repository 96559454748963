import { ClickAwayListener } from "@mui/material";
import { MouseEventHandler, memo, useEffect, useState } from "react";
import { buildNode, getNamedChildElement } from "../component-lists/helpers";
import { SharedState } from "../sharedState/SharedState";

/***
 * A component that renders a toggle switch.
 * This can be used for collapsing and expanding content, or for hiding/showing drawers etc.
 * The controllerElement is the element that is used to control the expansion.
 * The controlledElement is the element that is expanded or collapsed.
 * The clickAwayBreakpoint is the screen width below which a click away from the controlled element will close it.
 * The hiddenBelowBreakpoint is the screen width below which the controlled element is hidden when collapsed. By
 * using the hidden=until-found attribute, the browser will open the expansion when the user gets a search match.
 */
export const ToggleSwitch = memo(function ToggleSwitch(props: {
    sharedState: SharedState,
    name: string,
    default: boolean,
    useAriaControls: boolean,
    clickAwayBreakpoint: number,
    hiddenBelowBreakpoint: number,
    controllerElement: Element,
    controlledElement: Element
}): JSX.Element {
    const [on, setOn] = useState<boolean>(props.default || false);
    const [controlledNode, setControlledNode] = useState<Node | null>(null);


    // Force reload when the window is resized
    const [, setDimensions] = useState(props.sharedState.isClient ? {
        height: window.innerHeight,
        width: window.innerWidth
    } : { height: 0, width: 0 });

    useEffect(() => {
        if (props.sharedState.isClient) {
            function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth
                });
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [props.sharedState.isClient]);

    // Event handler for click on the controller
    const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
        manageHidden(!on, controlledNode as HTMLDivElement);
        setOn(!on);
        event.stopPropagation();
    }

    // Sets hidden=until-found to enable browser to open expansion when the user gets
    // a search match inside the collapsed content
    const manageHidden = (isOn: boolean, node: HTMLDivElement) => {
        if (isOn || window.innerWidth >= props.hiddenBelowBreakpoint) {
            node?.removeAttribute("hidden");
        }
        else {
            node?.setAttribute("hidden", "until-found");
        }
    }

    // Click-away handler for closing the expansion, typically used for narrow screens
    const handleClickAway = (evt: MouseEvent | TouchEvent) => {
        if (on && props.clickAwayBreakpoint && window.innerWidth < props.clickAwayBreakpoint) {
            manageHidden(false, controlledNode as HTMLDivElement);
            setOn(false);
            // Make sure the click away doesn't trigger the click on the button
            evt.stopPropagation();
            evt.preventDefault();
        }
    }

    // Add an effect that sets up an event listener for beforematch
    // This is used to open an expansion when the user gets a search match inside the collapsed content
    // We have to use native since the event is not exposed in React
    useEffect(() => {
        if (props.sharedState.isClient) {
            function handleBeforeMatch(evt: Event) {
                setOn(true);
            }
            if (controlledNode && props.hiddenBelowBreakpoint) {
                controlledNode.addEventListener("beforematch", handleBeforeMatch);
                return () => controlledNode.removeEventListener("beforematch", handleBeforeMatch);
            }
        }
    }, [controlledNode, props.hiddenBelowBreakpoint, props.sharedState.isClient]);

    const fixNativeNode = (node: HTMLDivElement | null) => {
        if (node && props.hiddenBelowBreakpoint) {
            setControlledNode(node);
            manageHidden(on, controlledNode as HTMLDivElement);
        }
    }

    const contentId = `_toggle-content-${props.name}`;
    const ariaProps = props.useAriaControls ? { "aria-expanded": on, "aria-controls": contentId } : {};

    return <>
        <div
            className="_toggle-button"
            onClick={handleClick}
            {...ariaProps}
        >{buildNode(props.sharedState, getNamedChildElement(props.controllerElement, null) || "", "0", {}, null, false)}</div>
        <ClickAwayListener onClickAway={handleClickAway}>
            <div
                id={contentId}
                ref={(node) => fixNativeNode(node)} className={`_toggle-content ${on ? "_toggle-on" : "_toggle-off"} `}
            >{buildNode(props.sharedState, getNamedChildElement(props.controlledElement, null) || "", "0", {}, null, false)}</div>
        </ClickAwayListener>
    </>
});