import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Merges multiple source items into one.
 * Returns an array containing one item.
 * Loads each source item from a named store.
 * 
 * Params:
 *    stores: Colon-separated list of store names, e.g. "store1:store2:store3".
 */
export class MergeSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }): Promise<Array<SourceItem>> {
        const mergedContext: SourceItem = {
            state: sharedState,
            value: "merged",
            props: {},
            position: 1,
        }
        const stores = params.stores.split(":");
        for (let store of stores) {
            if (sharedState.storedItems[store]) {
                const item = sharedState.storedItems[store];
                for (let propType in item.props) {
                    mergedContext.props[propType] = { ...mergedContext.props[propType], ...item.props[propType] };
                }
                if (mergedContext.combos || item.combos) {
                    mergedContext.combos = [...mergedContext.combos || [], ...item.combos || []];
                }
            }
        }
        return [mergedContext];
    }
}
