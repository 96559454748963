import { useState, useEffect, memo } from 'react';
import { buildContent } from "../component-lists/helpers";
import { SharedState } from "../sharedState/SharedState";
import { loadSourceArray } from "../source/Source";
import { SourceItem } from "../models/source-item.model";

/***
 * A component that iterates over an array of items and renders the content for each item.
 * The specification is used to load the array of items. It must be an ArraySource specification.
 */
export const ForEach = memo(function ForEach(props: {
  sharedState: SharedState,
  specification: string,
  store: string,
  content: Array<Node>,
}) {

  const [sourceArray, setSourceArray] = useState<Array<SourceItem>>([]);

  useEffect(() => {
    if (props.sharedState.isClient) {
      loadSourceArray(props.sharedState, props.specification).then((array: Array<SourceItem>) => {
        setSourceArray(array);
      });
    }
  }, [props.sharedState, props.specification]);

  const elements: Array<JSX.Element | string> = [];
  if (props.sharedState.isClient) {
    const serializer = new XMLSerializer();
    for (let ix in sourceArray) {
      const item = sourceArray[ix];
      item.template = "<div>" + props.content.map((node: Node) => {
        if (node.nodeType === 3) {
          if (node.textContent?.trim()) {
            return node.textContent.trim();
          }
        }
        else if (node.nodeType === 1) {
          return serializer.serializeToString(node);
        }
        return "";
      }).join("") + "</div>";
      const nextState = (item.state || props.sharedState).clone();
      if (props.store) {
        nextState.storedItems[props.store] = item;
      }
      const key: string = `iterator.${item.value}.${ix}`;
      for (let node of buildContent(nextState, props.content, key, [])) {
        elements.push(node);
      }
    }
  }

  return <>{elements}</>;
});