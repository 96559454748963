import { SourceItem } from "../../models/source-item.model";
import { SharedState } from "../../sharedState/SharedState";
import { ArrayModifier } from "../ArrayModifier";

/***
 * Filters an array of SourceItems, by removing the SourceItems with a specified value.
 * 
 *  * Params:
 *          remove: the value to remove
 */
export class FilterArrayModifier implements ArrayModifier {
    public modify(sharedState: SharedState, array: Array<SourceItem>, params: { [name: string]: string }): Array<SourceItem> {
        if (params.remove) {
            return array.filter(item => item.value.toString() !== params.remove).map((item, ix) => { return { ...item, position: ix + 1 } });
        }
        else {
            return array;
        }
    }
}