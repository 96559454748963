import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns a list of all system property names in the currently selected filter
 * 
 * Params: (none)
 */
export class QueryCustomNamesSource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        const nameItems: Array<SourceItem> = sharedState.site?.name ? await sharedState.backend.getPropertyNames(
            sharedState.site.name, sharedState.organization, sharedState.configuration, sharedState.filters) : [];
        return nameItems.map((item, ix) => {
            return { ...item, state: sharedState, position: ix + 1 };
        });
    }
}
