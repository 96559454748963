import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns a list of all property names of a certain category in a source item.
 * 
 * Params:
 *      store: the name of the store to load filter values from
 *      type: the property category
 */
export class PropertyNamesSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }): Promise<Array<SourceItem>> {
        const result: Array<SourceItem> = [];
        const props = sharedState.storedItems[params.store]?.props[params.type];
        if (props) {
            for (let ix = 0; ix < Object.keys(props).length; ix++) {
                const key = Object.keys(props)[ix];
                result.push({
                    state: sharedState,
                    value: key,
                    props: {},
                    position: ix,
                });
            }
        }
        return result;
    }
}