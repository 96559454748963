import isEqual from "lodash/isEqual";
import { SourceItem } from "../models/source-item.model";
import { Callback, SharedState } from "../sharedState/SharedState";
import { buildCartItem, saveCart } from "../utils/cart.utils";

/***
 * removeFromCart callback removes the current file from the download cart.
 */
export const removeFromCart: Callback = {
    func: (state: SharedState): void => {
        if (state.isClient) {
            const item = buildCartItem(state);
            if (item) {
                const index = state.cart.findIndex((cartItem: SourceItem) => {
                    return isEqualItems(cartItem, item);
                });
                if (index > -1) {
                    const newcart: Array<SourceItem> = [...state.cart];
                    newcart.splice(index, 1);
                    saveCart(newcart, state.site?.name || "unknown");
                }
            }
        }
    },
    enabledFunc: (state: SharedState): boolean => {
        let enabled = false;
        if (state.isClient) {
            const item = buildCartItem(state);
            if (item) {
                enabled = !!state.cart.find((cartItem: SourceItem) => {
                    return isEqualItems(cartItem, item);
                });
            }
        }

        return enabled;
    },
}

function isEqualItems(item1: SourceItem, item2: SourceItem): boolean {
    return item1.value === item2.value
        && isEqual(item1.props.system.lang, item2.props.system.lang)
        && isEqual(item1.props.system.edition, item2.props.system.edition)
        && isEqual(item1.props.validity, item2.props.validity);
}