import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { suppressUrlParameters } from "../../utils/link.utils";
import { ValueModifier } from "../ValueModifier";

/***
 * Formats a path into a link to the URL used for showing the file in the app.
 * 
 *  * Params:
 *     suppress: specification of what parts in the SharedState to suppress (not include) in the link
 */
export class LinkAppValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) {
        const path = (value || "").toString();
        const linkedState = suppressUrlParameters(sharedState, params.suppress || "");
        const urlQuery = linkedState.getUrlQuery();
        const urlQueryStr = urlQuery ? "?" + urlQuery : "";
        const link: string = sharedState.backend.getAppLink(linkedState.sitePath || "", path,
            linkedState.filters.validity, linkedState.filters.system) + urlQueryStr;
        return link;
    }
}