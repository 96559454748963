import { DOMParser as xmldomDOMParser } from "xmldom";

/***
 * Returns a DOMParser instance that can be used to parse XML documents.
 * 
 * Use this method instead of window.DOMParser because the latter is not
 * supported on the server.
 */
export function getDOMParser(): DOMParser {
    if (typeof (window) === "undefined" || typeof (window.DOMParser) === "undefined") {
        return new xmldomDOMParser();
    }
    else {
        return new window.DOMParser();
    }
}

/***
 * Returns the first child element of the specified element.
 * If the element is null or has no children, null is returned.
 * 
 * Use this method instead of element.firstElementChild because the latter is not
 * supported in the xmldom implementation used on the server.
 */
export function getFirstElementChild(element: Element | null): Element | null {
    if (element) {
        let node: Node | null = element.firstChild;
        while (node) {
            if (node.nodeType === 1) {
                return node as Element;
            }
            node = node.nextSibling;
        }
    }
    return null;

}

/***
 * Locates the insertion point in the loaded file (the files should not contain html, body and head tags but
 * if they do we skip them)
 */
export function findRoot(doc: Document | Element | null, pickId?: string): Element | null {
    if (doc) {
        if (pickId && doc.ownerDocument) {
            return doc.ownerDocument.getElementById(pickId);
        }

        let node: Node | null = doc.firstChild;
        while (node) {
            switch (node.nodeType) {
                case 1: // Node.ELEMENT_NODE:
                    switch ((node as Element).nodeName.toUpperCase()) {
                        case "HTML":
                        // falls through
                        case "BODY":
                            node = node.firstChild;
                            break;
                        case "HEAD":
                            node = node.nextSibling;
                            break;
                        default:
                            return node as Element;
                    }
                    break;
                default:
                    node = node.nextSibling;
                    break;
            }
        }

    }

    return null;
}