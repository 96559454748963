import { memo, useEffect, useState } from 'react';
import { SharedState } from "../sharedState/SharedState";
import { buildContent } from "../component-lists/helpers";
import { loadSourceArray } from "../source/Source";
import { SourceItem } from "../models/source-item.model";

/***
 * A component that stores an array of items in the shared state.
 * The specification is used to load the array of items. It must be an ArraySource specification.
 * This is a utility component that can be used anywhere and affects the shared state for all its
 * descendant content.
 */
export const StoreArray = memo(function StoreArray(props: {
    sharedState: SharedState,
    specification: string,
    store: string,
    content: Array<Node>,
}) {
    /***
    * The array loaded from the ArraySource
    */
    const [array, setArray] = useState<Array<SourceItem>>([]);

    /***
     * Load the content
     */
    useEffect(() => {
        if (props.sharedState.isClient) {
            let active = true;
            loadSourceArray(props.sharedState, props.specification).then(arr => {
                active && setArray(arr);
            });
            return () => {
                active = false;
            }
        }
    }, [props.specification, props.sharedState]);

    const nextState = props.sharedState.clone();
    if (props.store) {
        nextState.storedArrays[props.store] = array;
    }

    return <>{buildContent(nextState, props.content, "StoreArray", [])}</>;
});