import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { findRoot, getDOMParser, getFirstElementChild } from "../../../utils/dom.utils";
import { ArraySource } from "../../ArraySource";

/***
 * Scans the Dom on the current page and builds a tree.
 * 
 * Params:
 *          rootdef: css selector for the root node
 *          branchdef: css selector for branch nodes
 *          labeldef: css selector for the labels
 */
export class PageScanTreeSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        const doc: Document = getDOMParser().parseFromString(sharedState.content || "", "text/html");
        const rootNode = findRoot(doc);

        return this.findBranches(sharedState, rootNode || null, params.branchdef || "", params.labeldef || "");
    }

    private findBranches(sharedState: SharedState, root: Element | null, branchdef: string, labeldef: string): Array<SourceItem> {
        const items: Array<SourceItem> = [];
        let element: Element | null = root;
        while (element) {
            if (element.matches(branchdef)) {
                const label: Element | null = element.querySelector(labeldef);
                if (label) {
                    const item: SourceItem = {
                        state: sharedState,
                        value: label.textContent || "",
                        props: {
                            elements: {
                                id: [label.closest("[id]")?.getAttribute("id") || ""],
                            }
                        },
                        position: items.length + 1,
                    };
                    item.children = this.findBranches(sharedState, getFirstElementChild(element), branchdef, labeldef);
                    items.push(item);
                }
            }
            else {
                items.push(...this.findBranches(sharedState, getFirstElementChild(element), branchdef, labeldef));
            }
            element = element.nextElementSibling;
        }
        return items;
    }
}