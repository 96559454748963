import isEqual from "lodash/isEqual";
import { SourceItem } from "../models/source-item.model";
import { Callback, SharedState } from "../sharedState/SharedState";
import { buildCartItem, saveCart } from "../utils/cart.utils";

/***
 * addToCart callback adds the current file to the download cart.
 */
export const addToCart: Callback = {
    func: (state: SharedState): void => {
        if (state.isClient) {
            const item = buildCartItem(state);
            if (item) {
                if (!state.cart.find((cartItem: SourceItem) => {
                    return isEqual(cartItem, item);
                })) {
                    const newcart: Array<SourceItem> = [...state.cart];
                    newcart.push(item);
                    saveCart(newcart, state.site?.name || "unknown");
                    // TODO: Feedback to user            
                }
            }
        }
    },
    enabledFunc: (state: SharedState): boolean => {
        let enabled = false;
        if (state.isClient && state.path) {
            if (state.site?.files?.notForDownload?.includes(state.path)) {
                // Start page cannot be added to cart
                return false;
            }
            const item = buildCartItem(state);
            if (item) {
                enabled = !state.cart.find((cartItem: SourceItem) => {
                    return isEqual(cartItem, item);
                });
            }
        }

        return enabled;
    },
}

