const reactHTMLAttributeNameTranslation: { [name: string]: string } = {
    "accept-charset": "acceptCharset",
    "accesskey": "accessKey",
    "allowfullscreen": "allowFullScreen",
    "autocomplete": "autoComplete",
    "autofocus": "autoFocus",
    "autoplay": "autoPlay",
    "cellpadding": "cellPadding",
    "cellspacing": "cellSpacing",
    "class": "className",
    "classid": "classId",
    "classname": "className",
    "colspan": "colSpan",
    "contenteditable": "contentEditable",
    "controlslist": "controlsList",
    "datetime": "dateTime",
    "enctype": "encType",
    "frameborder": "frameBorder",
    "hreflang": "hrefLang",
    "for": "htmlFor",
    "inputmode": "inputMode",
    "maxlength": "maxLength",
    "mediagroup": "mediaGroup",
    "minlength": "minLength",
    "novalidate": "noValidate",
    "dadiogroup": "radioGroup",
    "readonly": "readOnly",
    "rowspan": "rowSpan",
    "spellcheck": "spellCheck",
    "style": "STYLE", // get around React's style property
    "tabindex": "tabIndex",
};

const reactSVGAttributeNameTranslation: { [name: string]: string } = {
    "accent-height": "accentHeight",
    "alignment-baseline": "alignmentBaseline",
    "allowreorder": "allowReorder",
    "arabic-form": "arabicForm",
    "attribute-name": "attributeName",
    "attribute-type": "attributeType",
    "auto-reverse": "autoReverse",
    "base-frequency": "baseFrequency",
    "baseline-shift": "baselineShift",
    "base-profile": "baseProfile",
    "calc-mode": "calcMode",
    "cap-height": "capHeight",
    "clip-path": "clipPath",
    "clippathunits": "clipPathUnits",
    "clip-rule": "clipRule",
    "color-interpolation": "colorInterpolation",
    "color-interpolation-filters": "colorInterpolationFilters",
    "color-profile": "colorProfile",
    "color-rendering": "colorRendering",
    "contentscripttype": "contentScriptType",
    "contentstyletype": "contentStyleType",
    "diffuse-constant": "diffuseConstant",
    "dominant-baseline": "dominantBaseline",
    "edgemode": "edgeMode",
    "enable-background": "enableBackground",
    "fill-opacity": "fillOpacity",
    "fill-rule": "fillRule",
    "filterres": "filterRes",
    "filterunits": "filterUnits",
    "flood-color": "floodColor",
    "flood-opacity": "floodOpacity",
    "font-family": "fontFamily",
    "font-size": "fontSize",
    "font-size-adjust": "fontSizeAdjust",
    "font-stretch": "fontStretch",
    "font-style": "fontStyle",
    "font-variant": "fontVariant",
    "font-weight": "fontWeight",
    "glyph-name": "glyphName",
    "glyph-orientation-horizontal": "glyphOrientationHorizontal",
    "glyph-orientation-vertical": "glyphOrientationVertical",
    "glyphref": "glyphRef",
    "gradienttransform": "gradientTransform",
    "gradientunits": "gradientUnits",
    "horiz-adv-x": "horizAdvX",
    "horiz-origin-x": "horizOriginX",
    "image-rendering": "imageRendering",
    "kernelmatrix": "kernelMatrix",
    "kernelunitlength": "kernelUnitLength",
    "keypoints": "keyPoints",
    "keysplines": "keySplines",
    "keytimes": "keyTimes",
    "lengthadjust": "lengthAdjust",
    "letter-spacing": "letterSpacing",
    "lighting-color": "lightingColor",
    "limitingconeAngle": "limitingConeAngle",
    "marker-end": "markerEnd",
    "markerheight": "markerHeight",
    "marker-mid": "markerMid",
    "marker-start": "markerStart",
    "markerunits": "markerUnits",
    "markerwidth": "markerWidth",
    "maskcontentunits": "maskContentUnits",
    "maskunits": "maskUnits",
    "numoctaves": "numOctaves",
    "overline-position": "overlinePosition",
    "overline-thickness": "overlineThickness",
    "panose-1": "panose1",
    "paint-order": "paintOrder",
    "pathlength": "pathLength",
    "patterncontentunits": "patternContentUnits",
    "patterntransform": "patternTransform",
    "patternunits": "patternUnits",
    "pointer-events": "pointerEvents",
    "pointsatx": "pointsAtX",
    "pointsaty": "pointsAtY",
    "pointsatz": "pointsAtZ",
    "preservealpha": "preserveAlpha",
    "preserveaspectratio": "preserveAspectRatio",
    "primitiveunits": "primitiveUnits",
    "refx": "refX",
    "refy": "refY",
    "rendering-intent": "renderingIntent",
    "repeatcount": "repeatCount",
    "repeatdur": "repeatDur",
    "requiredextensions": "requiredExtensions",
    "requiredfeatures": "requiredFeatures",
    "shape-rendering": "shapeRendering",
    "specularconstant": "specularConstant",
    "specularexponent": "specularExponent",
    "spreadmethod": "spreadMethod",
    "startoffset": "startOffset",
    "stddeviation": "stdDeviation",
    "stitchtiles": "stitchTiles",
    "stop-color": "stopColor",
    "stop-opacity": "stopOpacity",
    "strikethrough-position": "strikethroughPosition",
    "strikethrough-thickness": "strikethroughThickness",
    "stroke-dasharray": "strokeDasharray",
    "stroke-dashoffset": "strokeDashoffset",
    "stroke-linecap": "strokeLinecap",
    "stroke-linejoin": "strokeLinejoin",
    "stroke-miterlimit": "strokeMiterlimit",
    "stroke-opacity": "strokeOpacity",
    "stroke-width": "strokeWidth",
    "surfacescale": "surfaceScale",
    "systemlanguage": "systemLanguage",
    "tablevalues": "tableValues",
    "targetx": "targetX",
    "targety": "targetY",
    "text-anchor": "textAnchor",
    "text-decoration": "textDecoration",
    "textlength": "textLength",
    "text-rendering": "textRendering",
    "underline-position": "underlinePosition",
    "underline-thickness": "underlineThickness",
    "unicode-bidi": "unicodeBidi",
    "unicode-range": "unicodeRange",
    "units-per-em": "unitsPerEm",
    "v-alphabetic": "vAlphabetic",
    "vector-effect": "vectorEffect",
    "vert-adv-y": "vertAdvY",
    "vert-origin-x": "vertOriginX",
    "vert-origin-y": "vertOriginY",
    "v-hanging": "vHanging",
    "v-ideographic": "vIdeographic",
    "viewbox": "viewBox",
    "viewtarget": "viewTarget",
    "visibility": "visibility",
    "v-mathematical": "vMathematical",
    "word-spacing": "wordSpacing",
    "writing-mode": "writingMode",
    "xchannelselector": "xChannelSelector",
    "x-height": "xHeight",
    "xlink:actuate": "xlinkActuate",
    "xlink:arcrole": "xlinkArcrole",
    "xlink:href": "xlinkHref",
    "xlink:role": "xlinkRole",
    "xlink:show": "xlinkShow",
    "xlink:title": "xlinkTitle",
    "xlink:type": "xlinkType",
    "xml:base": "xmlBase",
    "xml:lang": "xmlLang",
    "xmlns:xlink": "xmlnsXlink",
    "xml:space": "xmlSpace",
    "ychannelselector": "yChannelSelector",
    "zoomandpan": "zoomAndPan",
}

/***
 * React uses camel case for HTML attributes, so some attributes need to be translated
 * to the react name
 */
export const reactAttributeNameTranslation: { [name: string]: string } = Object.assign(reactHTMLAttributeNameTranslation, reactSVGAttributeNameTranslation);