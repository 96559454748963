import { Input } from "@mui/material";
import { SharedState } from "../sharedState/SharedState";
import { memo } from "react";

/***
 * An input field that calls a callback function when the value changes.
 * Callback function must be added to the globalCallbacks object or the sharedState.callbacks object.
 */
export const CallbackInput = memo(function CallbackInput(props: { sharedState: SharedState, inputProps: any, field: string, callback: string }) {

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (event: any) => {
    const callback = props.sharedState.callbacks[props.callback];
    callback && callback.func(props.sharedState, { field: props.field, value: event.target.value });
  }

  return <Input {...props.inputProps} onChange={changeHandler} />
});