import { InjectElementRule, SharedState } from "../sharedState/SharedState";
import { buildContent } from "../component-lists/helpers";
import { memo } from "react";
import { getFirstElementChild } from "../utils/dom.utils";

/***
 * A component that adds elements to another element based on rules.
 * The rules are defined in child elements of the InjectElementRules element, andcan use
 * data in the sharedState to determine whether the element should be added.
 * The rules are evaluated by the renderNode helper function.
 * This method is primarily intended for adding markup to SVG images.
 */
export const InjectElementRules = memo(function InjectElementRules(props: {
  sharedState: SharedState,
  content: Array<Node>,
  applyToId: string,
}) {

  const parseRule = (ruleNode: Element): InjectElementRule | null => {
    const id = ruleNode.getAttribute("applyToId");
    const firstChildElement = getFirstElementChild(ruleNode);
    return (id && firstChildElement && { id: id, element: firstChildElement }) || null;
  };

  const rules: Array<InjectElementRule> = [];
  const contentNodes: Array<Node> = [];

  if (props.content) {
    for (let node of props.content) {
      if (node.nodeType === Node.ELEMENT_NODE && node.nodeName.toLowerCase() === 'rule') {
        const rule = parseRule(node as Element);
        rule && rules.push(rule);
      }
      else {
        contentNodes.push(node);
      }
    }
  }

  const nextState = rules.length ? Object.assign(props.sharedState.clone(), { injectElementRules: rules }) : props.sharedState;

  return <>{buildContent(nextState, contentNodes, `css-class-rule`, [])}</>;
});