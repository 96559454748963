import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns all existing values of a system property, after applying the current filters.
 * 
 * Params:
 *    name: The name of the custom property
 */
export class QuerySystemValuesSource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        const filters = { ...sharedState.filters };
        delete filters.system[params.name];
        const valueItems: Array<SourceItem> = sharedState.site?.name ?
            await sharedState.backend.getPropertyValueSet(sharedState.site.name,
                sharedState.organization, sharedState.configuration, filters, params.name, false, true)
            : [];
        return valueItems.map((item, ix) => { return { ...item, state: sharedState, position: ix + 1 }; });
    }
}