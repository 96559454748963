import { memo, useState } from 'react';
import { SharedState } from "../sharedState/SharedState";
import { Rating } from "@mui/material";

/***
 * A component that renders a rating.
 * This is currently not functional but is here for demo purposes.
 */
export const RatingWrapper = memo(function RatingWrapper(props: { sharedState: SharedState }): JSX.Element {

  const [value, setValue] = useState<number | null>(2);

  return (

    <Rating
      name="simple-controlled"
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    />
  );
});