import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns the values of a filter from the current state.
 * 
 * Params:
 *      type: The filter type
 *      name: The property name
 */
export class FilterSource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        const values: Array<SourceItem> = [];
        switch (params.type) {
            case "custom":
                const custom = sharedState.filters.custom[params.name || ""];
                if (custom) {
                    for (let ix = 0; ix < custom.length; ix++) {
                        const value = custom[ix];
                        values.push({
                            state: sharedState,
                            value: value,
                            props: {},
                            position: ix,
                        });
                    }
                }
                break;
            case "system":
                const system = sharedState.storedItems[params.store || ""]?.props.system[params.name || ""];
                if (system) {
                    for (let ix = 0; ix < system.length; ix++) {
                        const value = system[ix];
                        values.push({
                            state: sharedState,
                            value: value,
                            props: {},
                            position: ix,
                        });
                    }
                }
                break;
            case "validity":
                const validity = sharedState.storedItems[params.store || ""]?.props.validity[params.name || ""];
                if (validity) {
                    for (let ix = 0; ix < validity.length; ix++) {
                        const value = validity[ix];
                        values.push({
                            state: sharedState,
                            value: value,
                            props: {},
                            position: ix,
                        });
                    }
                }
                break;
        }
        return values;
    };
}