import { memo, useEffect, useState } from 'react';
import { SharedState } from "../sharedState/SharedState";
import { buildNode } from "../component-lists/helpers";
import { findRoot, getDOMParser, getFirstElementChild } from '../utils/dom.utils';

/***
 * A component that renders the contents of a specific path.
 * This can be used to render shared content which is not a part of the current page
 * and also not of the page wrapper.
 */
export const RenderPath = memo(function RenderPath(props: {
  sharedState: SharedState,
  path: string,
  ismain: boolean,
}) {
  const [url, setUrl] = useState<string>('');
  const [doc, setDoc] = useState<Document | null>(null);

  /***
   * Calculate URL for downloading file
   */
  useEffect(() => {
    if (props.sharedState.isClient) {
      if (props.sharedState.site && props.path) {
        const newUrl: string = props.sharedState.backend.getDirectLink(props.sharedState.site.name,
          props.sharedState.filters.slot || "production", props.path, props.sharedState.filters.validity,
          props.sharedState.filters.system);
        setUrl(newUrl);
      }
    }
  }, [props.sharedState, props.path])

  /***
   * Reload file whenever the url changes
   */
  useEffect(() => {
    if (props.sharedState.isClient) {
      let didCancel = false;

      async function loadDoc() {
        if (url) {
          const html: string = await props.sharedState.backend.getTextfile(url);
          if (!didCancel) {
            const doc: Document = getDOMParser().parseFromString(html, "text/html");
            generateIds(doc.documentElement);
            setDoc(doc);
          }
        }
      }

      loadDoc();
      return () => { didCancel = true; };
    }
  }, [url, props.sharedState.isClient, props.sharedState.backend])

  let content: JSX.Element | string = "";
  if (doc) {
    const rootNode = findRoot(doc);
    const nextState: SharedState = props.sharedState.clone();
    if (props.ismain) {
      // Set the new path
      if (props.path.startsWith("/")) {
        nextState.path = props.path;
      }
      else {
        nextState.path = `/${props.path}`;
      }
    }
    if (rootNode && rootNode.nodeType === Node.ELEMENT_NODE) {
      content = buildNode(nextState, rootNode as Element, "0", {}, null, false);
    }
  }

  return <>{content}</>;
});


/***
 * Generate ids for all elements that don't have one
 */
function generateIds(element: Element | null): void {
  let el: Element | null = element;
  while (el) {
    if (!el.hasAttribute("id")) {
      el.setAttribute("id", Date.now().toString() + Math.floor(Math.random() * 1000000).toString());
    }
    generateIds(getFirstElementChild(el));
    el = el.nextElementSibling;
  }
}
