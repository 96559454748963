import { memo, useEffect, useState } from "react";
import { SharedState } from "../sharedState/SharedState";
import { globalCallbacks } from "../callbacks/callbacks";
import { useNavigate } from "react-router-dom";
import { buildContent } from "../component-lists/helpers";

/***
 * A button that calls a callback function.
 * Callback function must be added to the globalCallbacks object or the sharedState.callbacks object.
 * The button label can either be supplied in the label property or as content of the CallbackButton
 * element.
 */
export const CallbackButton = memo(function CallbackButton(props: {
  sharedState: SharedState,
  label: string,
  callback: string,
  requireValidForm: boolean,
  content: Array<Node>,
}) {

  const useNavigateOnClient = props.sharedState.isClient ? useNavigate : () => () => { };
  const navigate = useNavigateOnClient();

  // Use state and effect for setting disabled, so it's always true on the first render
  // This way SSR works without messing up the disabled attribute
  const [disabled, setDisabled] = useState<boolean>(true);
  useEffect(() => {
    let disabled = false;
    if (props.requireValidForm && props.sharedState.isValidForm === false) {
      disabled = true;
    }
    const enabledFunc = globalCallbacks[props.callback]?.enabledFunc;
    if (enabledFunc && !enabledFunc(props.sharedState, {})) {
      disabled = true;
    }
    setDisabled(disabled);
  }, [props.sharedState, props.callback, props.requireValidForm]);

  const clickHandler: React.MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement>) => {
    const callback = globalCallbacks[props.callback] || props.sharedState.callbacks[props.callback];
    callback && callback.func(props.sharedState, {});
    if (props.sharedState.isClient) {
      const navigateTo = window?.location?.pathname + window?.location?.hash + window?.location?.search || "";
      navigate(navigateTo, { replace: true }); // Force update
    }
  }

  const buttonLabel: string | Array<string | JSX.Element> = props.label || buildContent(props.sharedState, props.content, `callback-button-label`, []);
  if (disabled) {
    // Use condition because React SSR messes up the attribute
    return <button disabled={disabled} onClick={clickHandler}>{buttonLabel}</button>
  }
  else {
    return <button onClick={clickHandler}>{buttonLabel}</button>
  }

});