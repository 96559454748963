import { memo } from "react";
import { buildNode } from "../component-lists/helpers";
import { SharedState } from "../sharedState/SharedState";
import { findRoot, getDOMParser } from "../utils/dom.utils";

/***
 * A component that renders its content for each child of a stored item.
 * It makes it possible to render a recursive structure, such as a table of content tree.
 * It must be used inside a ForEach component. The template of the ForEach component should
 * include a ForChildren component and the same template is reused for all children recursively.
 */
export const ForChildren = memo(function ForChildren(props: {
  sharedState: SharedState,
  store: string,
}) {
  const elements: Array<JSX.Element | string> = [];
  const item = props.sharedState.storedItems[props.store];

  const htmlTemplateDoc: Document = getDOMParser().parseFromString(item.template || "", "text/html");
  const htmlTemplate: Element | null = findRoot(htmlTemplateDoc);

  if (htmlTemplate) {
    if (item && item.children && item.template) {
      for (let childItem of item.children) {
        childItem.template = item.template;
        const nextState = (childItem.state || props.sharedState).clone();
        nextState.storedItems[props.store] = childItem;
        const content = buildNode(nextState, htmlTemplate, `iterator.${childItem.value}.${childItem.position}`, {}, null, false);
        elements.push(content);
      };
    }
  }
  return (
    <>{elements}</>
  );
});
