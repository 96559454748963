import { SourceItem } from "../models/source-item.model";

export interface SearchDifference {
    new: Array<SourceItem>,
    removed: Array<SourceItem>,
    modified: Array<SourceItem>,
}

/***
 * Calculates the difference between two lists of item sources
 */
export function compareSearch(baselineList: Array<SourceItem>, currentList: Array<SourceItem>): SearchDifference {
    const difference: SearchDifference = { new: [...currentList], removed: [], modified: [] };
    for (let baselineItem of baselineList) {
        let found: boolean = false;
        for (let currentIx = 0; currentIx < difference.new.length; currentIx++) {
            if (baselineItem.props.system?.path?.[0] === difference.new[currentIx].props.system.path?.[0]) {
                if (baselineItem.value !== difference.new[currentIx].value) {
                    difference.modified.push(difference.new[currentIx]);
                }
                difference.new.splice(currentIx, 1);
                found = true;
                break;
            }
        }
        found || difference.removed.push(baselineItem);
    }
    return difference;
}
