import { Metadata, PropertiesModel, ValidityPropertyModel } from "../models/metadata.model";
import qs from "qs";
import { Terms } from "../models/terms.model";
import { Settings } from "../models/settings.model";
import { SourceItem } from "../models/source-item.model";
import { Backend } from "./backend";
import { Configuration } from "../models/configuration.model";
import { Organization } from "../models/organization.model";
import { FilterModel } from "../models/filter.model";

/***
 * This is the standard backend implementation that uses the API server to get data.
 */
export class ApiBackend implements Backend {

    serverUrl = process.env.REACT_APP_API_URL || "/app";

    public getName = (): string => {
        return "API";
    }

    public getTextfile = async (
        url: string,
    ): Promise<string> => {
        const res = await fetch(url);
        return res.status === 200 ? res.text() : "";
    }

    public getDirectLink = (
        site: string,
        slot: string | null,
        path: string,
        validity: Array<ValidityPropertyModel>,
        systemProperties: PropertiesModel,
    ): string => {
        const pathWithLeadingSlash = path.startsWith("/") ? path : "/" + path;
        const urlParams: { [key: string]: any } = {};
        if (slot) {
            urlParams.slot = slot;
        }
        if (validity.length) {
            urlParams.v = validity;
        }
        if (Object.keys(systemProperties).length) {
            urlParams.s = systemProperties;
        }
        const urlParamsStr = qs.stringify(urlParams);
        let link = `${this.serverUrl}/file/${site || ''}${pathWithLeadingSlash}`
        if (urlParamsStr) {
            link += `?${urlParamsStr}`;
        }
        return link;
    }

    public getAppLink = (
        sitePath: string,
        path: string,
        _validity: Array<ValidityPropertyModel>,
        _systemProperties: PropertiesModel,
    ): string => {
        return `${sitePath}${path}`;
    }

    public search = async (
        site: string,
        org: string | null,
        cfg: string | null,
        filters: FilterModel,
    ): Promise<Array<Metadata>> => {
        const res = await fetch(`${this.serverUrl}/search/metadata/${site}${this.getUrlQueryInfo(org, cfg, filters)}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (res.status === 200) {
            return res.json().then((jsonObj) => {
                this.walkJson(jsonObj, (parent: { [key: string]: any }, key: string, value: any) => {
                    if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
                        // Express server sends dates in ISO string format. To get the real date values back we parse
                        // any such string into Date
                        parent[key] = new Date(Date.parse(value));
                    }
                });
                return jsonObj;
            });
        }
        else {
            return [];
        }
    }

    public getPropertyValueSet = async (
        site: string,
        org: string | null,
        cfg: string | null,
        filters: FilterModel,
        propertyName: string,
        isValidity: boolean,
        isSystemProperty: boolean,
    ): Promise<Array<SourceItem>> => {
        const res = await fetch(`${this.serverUrl}/search/valueset/${site}/${propertyName}${this.getUrlQueryInfo(org, cfg, filters)}&isValidity=${isValidity}&isSystemProperty=${isSystemProperty}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const items: Array<SourceItem> = res.status === 200 ? await res.json() : null;
        if (items) {
            return items.map((obj, ix) => { return { ...obj, position: ix + 1 } });
        }
        return [];
    }

    public getPropertyNames = async (
        site: string,
        org: string | null,
        cfg: string | null,
        filters: FilterModel,
    ): Promise<Array<SourceItem>> => {
        const res = await fetch(`${this.serverUrl}/search/propertynames/${site}${this.getUrlQueryInfo(org, cfg, filters)}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const items: Array<SourceItem> = res.status === 200 ? await res.json() : null;
        if (items) {
            return items.map((obj, ix) => { return { ...obj, position: ix + 1 } });
        }
        return [];
    }

    public getTerms = async (
        site: string,
        slot: string | null,
    ): Promise<Terms> => {
        const res = await fetch(`${this.serverUrl}/terms/${site}?${slot ? '&slot=' + slot : ''}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public getConfiguration = async (
        site: string,
        slot: string | null,
        id: string,
    ): Promise<Configuration> => {
        const res = await fetch(`${this.serverUrl}/configuration/${site}/${id}?${slot ? '&slot=' + slot : ''}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public getOrganization = async (
        site: string,
        slot: string | null,
        id: string,
    ): Promise<Organization> => {
        const res = await fetch(`${this.serverUrl}/organization/${site}/${id}?${slot ? '&slot=' + slot : ''}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public getSettings = async (
        site: string,
        slot: string | null,
        orgId: string | null,
        cfgId: string | null,
    ): Promise<Settings> => {
        const res = await fetch(`${this.serverUrl}/settings/${site}?${slot ? '&slot=' + slot : ''}${orgId ? '&org=' + orgId : ''}${cfgId ? '&cfg=' + cfgId : ''}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public submitForm = async (
        site: string,
        name: string,
        data: { [key: string]: string | boolean },
    ): Promise<{ success: boolean, message: string | null }> => {
        return await fetch(`${this.serverUrl}/submit/${site}/${name}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((res: Response) => {
            return res.json();
        }).catch((err: Error) => {
            return { success: false, message: err.message };
        });
    }

    private walkJson(jsonObj: any, callback: (parent: object, key: string, value: any) => void) {
        for (var key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                var value = jsonObj[key];
                callback(jsonObj, key, value);
                if (typeof value === "object" && value !== null) {
                    this.walkJson(value, callback);
                }
            }
        }
    }

    private getUrlQueryInfo(org: string | null, cfg: string | null, filters: FilterModel) {
        const cleanedFilters: { [key: string]: any } = { ...filters };
        for (const key in cleanedFilters) {
            if (cleanedFilters.hasOwnProperty(key)) {
                if (cleanedFilters[key] === null || cleanedFilters[key] === undefined || cleanedFilters[key] === "") {
                    delete cleanedFilters[key];
                }
            }
        }
        return "?filters=" + JSON.stringify(cleanedFilters) + (org ? "&org=" + org : "") + (cfg ? "&cfg=" + cfg : "");
    }

    public getDownloadLink = (
        site: string,
        slot: string | null,
        items: Array<SourceItem>,
        wrapperfile: string | null,
        indexfile: string | null
    ): string => {
        return `${this.serverUrl}/download/${site || ''}/${slot || ''}?items=${JSON.stringify(items)}${wrapperfile ? '&wrapperfile=' + wrapperfile : ''}${indexfile ? '&indexfile=' + indexfile : ''}`;
    };

}