import { Metadata } from "../models/metadata.model";
import { SourceItem } from "../models/source-item.model";

export function mapMetadataToItem(metadata: Metadata): SourceItem {
    const item: SourceItem = {
        value: metadata._id?.str || "",
        props: {
            system: {
                path: [metadata.path],
            }
        },
    }
    metadata.edition && (item.props.system.edition = [metadata.edition]);
    metadata.height && (item.props.system.height = [metadata.height]);
    metadata.lang && (item.props.system.lang = [metadata.lang]);
    metadata.microcontent && (item.props.system.microcontent = [metadata.microcontent]);
    metadata.lastModified && (item.props.system.modified = [metadata.lastModified]);
    metadata.title && (item.props.system.title = [metadata.title]);
    metadata.type && (item.props.system.type = [metadata.type]);
    metadata.properties && (item.props.custom = metadata.properties);
    metadata.validity && (item.combos = metadata.validity);
    metadata.width && (item.props.system.width = [metadata.width]);
    return item;
}