/* eslint no-eval: 0 */

import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import concat from "lodash/concat";
import React from "react";
import { CssClassRule, InjectElementRule, SharedState } from "../sharedState/SharedState";
import { reactAttributeNameTranslation } from "./attributeMaps";
import { findRoot, getDOMParser, getFirstElementChild } from "../utils/dom.utils";
import { resolveValue } from "../source/Source";
import { globalCallbacks } from "../callbacks/callbacks";
import { ExpandMore } from '@mui/icons-material';
import { ModifyState } from "../components/ModifyState";
import { PageLoader } from "../components/PageLoader";
import { RatingWrapper } from '../components/RatingWrapper';
import { RenderPath } from '../components/RenderPath';
import { DisplayValueControl } from '../components/DisplayValueControl';
import { CssClassRules } from '../components/CssClassRules';
import { InjectElementRules } from '../components/InjectElementRules';
import { OpenDialogButton } from '../components/OpenDialogButton';
import { CallbackButton } from '../components/CallbackButton';
import { CallbackInput } from '../components/CallbackInput';
import { FormManager } from '../components/FormManager';
import { StoreMetadata } from '../components/StoreMetadata';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { StoreArray } from '../components/StoreArray';
import { ForChildren } from '../components/ForChildren';
import { ForEach } from '../components/ForEach';
import { DownloadCart } from '../components/DownloadCart';
import { TextFilter } from '../components/TextFilter';
import { Selector } from '../components/Selector';
import { IncludeImage } from "../components/IncludeImage";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AppBar from "@mui/material/AppBar";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";

/***
 * List of all supported React components in included files
 */
interface ComponentDef {
  name: string,
  isSitesComponent?: boolean,
  removeTextNodes?: boolean,
  ignoreChildNodes?: boolean,
  props: { [key: string]: (element: Element, attrName: string, props: any, sharedState: SharedState) => any },
  passAllAttributesAsProperties?: boolean,
  transform: (element: Element, children: Array<string | JSX.Element>, props: any) => JSX.Element
}

const muiComponents: Array<ComponentDef> = [
  {
    name: "Accordion",
    removeTextNodes: true,
    props: {
      defaultExpanded: getBooleanAttribute,
      disablegutters: getStringAttribute,
      square: getBooleanAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Accordion {...props}>{children}</Accordion>
    }
  },
  {
    name: "AccordionDetails",
    props: {
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <AccordionDetails {...props}>{children}</AccordionDetails>
    }
  },
  {
    name: "AccordionSummary",
    props: {
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <AccordionSummary expandIcon={<ExpandMore />} {...props}>{children}</AccordionSummary>
    }
  },
  {
    name: "AppBar",
    props: {
      position: getStringAttribute
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <AppBar {...props}>{children}</AppBar>
    }
  },
  {
    name: "Autocomplete",
    ignoreChildNodes: true,
    props: {
      autoComplete: getBooleanAttribute,
      autoSelect: getBooleanAttribute,
      clearOnBlur: getBooleanAttribute,
      clearOnEscape: getBooleanAttribute,
      disableClearable: getBooleanAttribute,
      disableCloseOnSelect: getBooleanAttribute,
      disabledItemsFocusable: getBooleanAttribute,
      disableListWrap: getBooleanAttribute,
      disablePortal: getBooleanAttribute,
      filterSelectedOptions: getBooleanAttribute,
      freeSolo: getBooleanAttribute,
      fullWidth: getBooleanAttribute,
      handleHomeEndKeys: getBooleanAttribute,
      id: getStringAttribute,
      includeInputInList: getBooleanAttribute,
      limitTags: getNumberAttribute,
      multiple: getBooleanAttribute,
      openOnFocus: getBooleanAttribute,
      selectOnFocus: getBooleanAttribute,
      size: getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Autocomplete {...props}></Autocomplete>
    }
  },
  {
    name: "Avatar",
    props: {
      "alt": getStringAttribute,
      "variant": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Avatar {...props}>{children}</Avatar>
    }
  },
  {
    name: "Box",
    props: {
      "display": getStringAttribute,
      "flexDirection": getStringAttribute,
      "flexWrap": getStringAttribute,
      "flexGrow": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Box {...props}>{children}</Box>
    }
  },
  {
    name: "Card",
    props: {
      "raised": getBooleanAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Card {...props}>{children}</Card>
    }
  },
  {
    name: "CardContent",
    props: {},
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <CardContent {...props}>{children}</CardContent>
    }
  },
  {
    name: "CardHeader",
    props: {
      "avatar": getNodeAttributeComponent,
      "subheader": getNodeAttributeComponent,
      "title": getNodeAttributeComponent,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <CardHeader {...props}></CardHeader>
    }
  },
  {
    name: "Checkbox",
    ignoreChildNodes: true,
    props: {
      "checkedIcon": getNodeAttributeComponent,
      "color": getStringAttribute,
      "disableRipple": getBooleanAttribute,
      "icon": getNodeAttributeComponent,
      "inputProps": getObjectAttribute,
      "required": getBooleanAttribute,
      "size": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Checkbox {...props}></Checkbox>
    }
  },
  {
    name: "Chip",
    ignoreChildNodes: true,
    props: {
      "label": getNodeAttributeComponent,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Chip {...props}></Chip>
    }
  },
  {
    name: "Container",
    props: {
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Container {...props}>{children}</Container>
    }
  },
  {
    name: "Grid",
    props: {
      "container": getBooleanAttribute,
      "item": getBooleanAttribute,
      "direction": getStringAttribute,
      "spacing": getSpacingAttribute,
      "xs": getXsAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Grid {...props}>{children}</Grid>
    }
  },
  {
    name: "Hidden",
    props: {
      "lgDown": getBooleanAttribute,
      "lgUp": getBooleanAttribute,
      "mdDown": getBooleanAttribute,
      "mdUp": getBooleanAttribute,
      "smDown": getBooleanAttribute,
      "smUp": getBooleanAttribute,
      "xlDown": getBooleanAttribute,
      "xlUp": getBooleanAttribute,
      "xsDown": getBooleanAttribute,
      "xsUp": getBooleanAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Hidden {...props}>{children}</Hidden>
    }
  },
  {
    name: "TextField",
    props: {
      "autoComplete": getStringAttribute,
      "autoFocus": getBooleanAttribute,
      "color": getStringAttribute,
      "fullWidth": getBooleanAttribute,
      "InputLabelProps": getObjectAttribute,
      // "inputProps": getObjectAttribute,
      "InputProps": getObjectAttribute,
      "margin": getStringAttribute,
      "maxRows": getNumberAttribute,
      "minRows": getNumberAttribute,
      "multiline": getBooleanAttribute,
      "name": getStringAttribute,
      "placeholder": getStringAttribute,
      "required": getBooleanAttribute,
      "rows": getNumberAttribute,
      "size": getStringAttribute,
      "type": getStringAttribute,
      "variant": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <TextField {...props}></TextField>
    }
  },
  {
    name: "Toolbar",
    props: {
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Toolbar {...props}>{children}</Toolbar>
    }
  },
  {
    name: "Typography",
    props: {
      "align": getStringAttribute,
      "color": getStringAttribute,
      "display": getStringAttribute,
      "noWrap": getBooleanAttribute,
      "paragraph": getBooleanAttribute,
      "variant": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Typography {...props}>{children}</Typography>
    }
  },
]

// Add properties that are common for all MUI components
muiComponents.forEach(item => {
  item.props["className"] = getStringAttribute;
  item.props["sx"] = getSxAttribute;
});

const sitesComponents: Array<ComponentDef> = [
  {
    name: "CallbackButton",
    props: {
      label: getStringAttribute,
      callback: getStringAttribute,
      requireValidForm: getBooleanAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <CallbackButton {...props} content={getChildNodes(element)} />
    }
  },
  {
    name: "CallbackInput",
    props: {
      inputProps: getObjectAttribute,
      field: getStringAttribute,
      callback: getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <CallbackInput {...props} />
    }
  },
  {
    name: "CssClassRules",
    ignoreChildNodes: true,
    props: {
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <CssClassRules {...props} content={getChildNodes(element)} />
    }
  },
  {
    name: "DisplayValueControl",
    ignoreChildNodes: true,
    props: {
      "name": getStringAttribute,
      "startTrue": getBooleanAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <DisplayValueControl {...props} content={getChildNodes(element)} />
    }
  },
  {
    name: "DownloadCart",
    ignoreChildNodes: true,
    props: {
      "indexfile": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <DownloadCart {...props} />
    }
  },
  {
    name: "ForChildren",
    ignoreChildNodes: true,
    props: {
      "store": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <ForChildren {...props} content={getChildNodes(element)}></ForChildren>;
    }
  },
  {
    name: "ForEach",
    ignoreChildNodes: true,
    props: {
      "specification": getPartlyResolvedStringAttribute,
      "store": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <ForEach {...props} content={getChildNodes(element)}></ForEach>;
    }
  },
  {
    name: "FormManager",
    ignoreChildNodes: true,
    props: {
      "outsideFormTemplate": getTemplateGetter("OutsideFormTemplate"),
      "requiredFields": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <FormManager {...props} content={getChildNodes(element)}></FormManager>;
    }
  },
  {
    name: "IncludeImage",
    ignoreChildNodes: true,
    props: {
      "path": getStringAttribute,
      "alt": getStringAttribute,
      "width": getStringAttribute,
      "height": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <IncludeImage {...props}></IncludeImage>
    }
  },
  {
    name: "InjectElementRules",
    ignoreChildNodes: true,
    props: {
      "applyToId": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <InjectElementRules {...props} content={getChildNodes(element)} />
    }
  },
  {
    name: "ModifyState",
    ignoreChildNodes: true,
    props: {
      "specification": getPartlyResolvedStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <ModifyState {...props} content={getChildNodes(element)} />;
    }
  },
  {
    name: "OpenDialogButton",
    props: {
      label: getStringAttribute
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <OpenDialogButton {...props} />
    }
  },
  {
    name: "PageLoader",
    ignoreChildNodes: true,
    props: {},
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <PageLoader {...props} />
    }
  },
  {
    // Deprecated form of PageLoader, kept for backwards compatibility
    name: "PageRouter",
    ignoreChildNodes: true,
    props: {},
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <PageLoader {...props} />
    }
  },
  {
    name: "RatingWrapper",
    ignoreChildNodes: true,
    props: {
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <RatingWrapper {...props} />
    }
  },
  {
    name: "RenderPath",
    ignoreChildNodes: true,
    props: {
      "path": getStringAttribute,
      "ismain": getBooleanAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <RenderPath {...props}></RenderPath>
    }
  },
  {
    name: "Selector",
    ignoreChildNodes: true,
    props: {
      "specification": getPartlyResolvedStringAttribute,
      "persist": getStringAttribute,
      "default": getStringAttribute,
      "presentation": getUnresolvedStringAttribute,
      "label": getStringAttribute,
      "autocompleteProps": getObjectAttribute,
      "redirect": getStringAttribute,
      "textfieldProps": getObjectAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <Selector {...props} content={getChildNodes(element)} />
    }
  },
  {
    name: "StoreArray",
    ignoreChildNodes: true,
    props: {
      "specification": getPartlyResolvedStringAttribute,
      "store": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <StoreArray {...props} content={getChildNodes(element)}></StoreArray>
    }
  }, {
    name: "StoreMetadata",
    ignoreChildNodes: true,
    props: {
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <StoreMetadata {...props} content={getChildNodes(element)}></StoreMetadata>
    }
  },
  {
    name: "TextFilter",
    ignoreChildNodes: true,
    props: {
      "renderer": getStringAttribute,
      "redirect": getStringAttribute,
      "autocompleteProps": getObjectAttribute,
      "textfieldProps": getObjectAttribute,
      "label": getStringAttribute,
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <TextFilter {...props} content={getChildNodes(element)} />
    }
  },
  {
    name: "ToggleSwitch",
    ignoreChildNodes: true,
    props: {
      "name": getStringAttribute,
      "default": getBooleanAttribute,
      "useAriaControls": getBooleanAttribute,
      "clickAwayBreakpoint": getNumberAttribute,
      "hiddenBelowBreakpoint": getNumberAttribute,
      "controllerElement": getTemplateGetter("Controller"),
      "controlledElement": getTemplateGetter("Controlled"),
    },
    transform: (element: Element, children: Array<string | JSX.Element>, props: any): JSX.Element => {
      return <ToggleSwitch {...props} />
    }
  },
];

sitesComponents.forEach(item => {
  item.isSitesComponent = true;
});

export const components: Array<ComponentDef> = concat(sitesComponents, muiComponents);

// Add properties that are common for all components
components.forEach(item => {
  item.props["id"] = getStringAttribute;
  item.props["data-diff"] = getStringAttribute;
});

/***
 * Recursively builds one HTML node from the loaded file. We must handle each element separately and add 
 * it as a JSX.Element, otherwise React won't recognize components inside the content.
 */
export function buildNode(sharedState: SharedState, node: Node | string, ref: string, extraProps: { [key: string]: any }, overrideChildren: null | Array<JSX.Element | string>, sources: boolean): string | JSX.Element {
  const props: { [key: string]: any } = cloneDeep(extraProps);
  let useSources = sources;
  if (typeof node === 'string' || node instanceof String) {
    return node.toString();
  }
  else {
    if (node.nodeType === 1) { // Node.ELEMENT_NODE
      const element = node as Element;
      const elementName = (element.getAttribute("data-nodename") || element.nodeName).toLowerCase();

      /***
       * Parse attributes into React properties
       */
      props.key = "element." + node.nodeName + "." + ref;
      for (let ix = 0; ix < element.attributes.length; ix++) {
        const attr = element.attributes[ix];
        switch (attr.name.toLowerCase()) {
          case "sources":
            useSources = attr.value === "true";
            break;
          case "onclick-callback":
            const callback = globalCallbacks[attr.value] || sharedState.callbacks[attr.value];
            if (callback) {
              props.onClick = () => callback.func(sharedState, {});
            }
            break;
          default:
            const attrValue: string = resolveValue(sharedState, attr.value || undefined, false) || "";
            props[reactAttributeNameTranslation[attr.name.toLowerCase()] || attr.name.toLowerCase()] = attrValue || "";
        }
      }

      /***
       * Handle conditions
       */
      if (props["data-condition"]) {
        const expression: string = resolveValue(sharedState, props["data-condition"] || undefined, false) || "";
        if (!evaluateBooleanExpression(expression)) {
          return "";
        }
      }

      /***
       * Handle Css class rules
       */
      if (props.id) {
        for (let ruleIx = 0; ruleIx < sharedState.cssClassRules.length; ruleIx++) {
          const cssRule: CssClassRule = sharedState.cssClassRules[ruleIx];
          if (cssRule.id === props.id) {
            props.className = runCssRule(sharedState, cssRule.rule, cssRule.className, props.className);
          }
        }
      }

      /***
       * Handle Inject element rules
       */
      if (props.id) {
        for (let ruleIx = 0; ruleIx < sharedState.injectElementRules.length; ruleIx++) {
          const injectRule: InjectElementRule = sharedState.injectElementRules[ruleIx];
          if (element.nodeType === 1 && injectRule.id === props.id && injectRule.element) {
            const nextState: SharedState = cloneDeep(sharedState);
            // Remove this rule in child state so it does not get run again
            nextState.injectElementRules = filter(nextState.injectElementRules || [], rule => rule.id !== props.id);
            // const child = buildNode(nextState, element, "0", extraProps, overrideChildren);            
            const cloned: Element = injectRule.element.cloneNode(true) as Element;
            cloned.appendChild(element.cloneNode(true));
            return buildNode(nextState, cloned, "rule." + ref + "." + ruleIx, {}, [], useSources);
          }
        }
      }

      const component = find(components, o => { return o.name.toLowerCase() === elementName });
      if (component) {
        if (component.passAllAttributesAsProperties) {
          for (let ix = 0; ix < element.attributes.length; ix++) {
            const attr = element.attributes[ix];
            props[attr.name] = attr.value;
          }
        }
        if (component.isSitesComponent) {
          Object.assign(props, { sharedState: sharedState });
        }
        // Parse and add properties depending on the props definition for the component
        for (let propName in component.props) {
          component.props[propName](element, propName, props, sharedState);
        }
        return component.transform(element, component.ignoreChildNodes ? [] : buildChildren(sharedState, element, ref, component.removeTextNodes, useSources), props);
      }
      else {
        const isVoidElement = ["area", "base", "br", "col", "embed", "hr", "img", "input", "link", "meta", "param", "source", "track", "wbr"].includes(elementName.toLowerCase());
        if (isVoidElement) {
          // Void elements cannot have children
          return React.createElement(elementName, props);
        }
        else {
          return React.createElement(elementName, props, overrideChildren || buildChildren(sharedState, element, ref, false, useSources));
        }
      }
    }
    else if (node.nodeType === 3) { // Node.TEXT_NODE
      if (useSources && node.textContent?.trim()) {
        // If @sources is true, the text node content could contain expressions, and must be resolved
        // The value could contain tags, so we also have to parse the value as HTML and build the children
        const resolvedValue = resolveValue(sharedState, node.textContent.trim() || "", false);
        const document: Document = getDOMParser().parseFromString(`<div id="value">${resolvedValue}</div>`, "text/html");
        const element = document.getElementById("value");
        if (element) {
          const content: Array<string | JSX.Element> = buildChildren(sharedState, element, "value." + ref, false, false);
          if (content.length) {
            return <span key={ref}>{content}</span>;
          }
        }
      }
      else {
        return node.textContent || "";
      }
    }
    return "";
  }
}

/***
 * Evaluates an expression and returns true or false
 */
export function evaluateBooleanExpression(expression: string): boolean {
  return validateRuleExpression(expression) ? !!eval(expression) : false;
}

/***
 * Run a css rule expression in the context of the current shared state and set/unset the rule's css class
 * in the class attribute string depending on the result of the expression
 */
export function runCssRule(sharedState: SharedState, rule: string, applyOnClass: string, classAttribute: string | null): string {
  // Replace references to shared state values in the string with the current value
  const expression: string = resolveValue(sharedState, rule || undefined, false) || "";
  const stripped: string = filter((classAttribute || "").split(" "), cl => cl !== applyOnClass).join(' ');
  return evaluateBooleanExpression(expression) ? (stripped + ' ' + applyOnClass).trim() : stripped;
}

/***
 * Validate a expression to make sure it's safe to use
 */
export function validateRuleExpression(expression: string): boolean {
  let isInSingleQuotes = false;
  let isInDoubleQuotes = false;
  const teststring = expression.replaceAll("true", "").replaceAll("false", "").replaceAll("includes", "");
  if (teststring.length > 2000) {
    // Safeguard against potential overflow attacks
    return false;
  }
  for (let ix = 0; ix < teststring.length; ix++) {
    let isLegal = false;
    const ch = teststring[ix];
    if ("!&()=|<> 0123456789".includes(ch)) {
      isLegal = true;
    }
    else {
      if (isInSingleQuotes) {
        isLegal = true;
        if ("'" === ch) {
          isInSingleQuotes = false;
        }
      }
      else if (isInDoubleQuotes) {
        isLegal = true;
        if ('"' === ch) {
          isInDoubleQuotes = false;
        }
      }
      else if ("'" === ch) {
        isInSingleQuotes = true;
        isLegal = true;
      }
      else if ('"' === ch) {
        isInDoubleQuotes = true;
        isLegal = true;
      }
    }
    if (!isLegal) {
      return false;
    }
  }
  return true;
}

/***
 * Creates the list of child nodes to add in an element
 */
export function buildChildren(sharedState: SharedState, sourceElement: Element, ref: string, removeTextNodes: boolean | undefined, sources: boolean): Array<string | JSX.Element> {
  const children: Array<string | JSX.Element> = [];
  for (let i = 0; i < sourceElement.childNodes.length; i++) {
    if (!removeTextNodes || sourceElement.childNodes[i].nodeType !== 3) {
      const childNode = buildNode(sharedState, sourceElement.childNodes[i], `${ref}.${i}`, {}, null, sources);
      if (childNode) {
        children.push(childNode);
      }
    }
  }
  return children;
}

export function buildContent(sharedState: SharedState, template: Array<Node>, key: string, skipElementNames: Array<string>): Array<JSX.Element | string> {
  const content: Array<string | JSX.Element> = [];
  for (let nodeIx in template) {
    const node = template[nodeIx];
    if (node.nodeType === 1) { // Node.ELEMENT_NODE
      if (!skipElementNames.includes(node.nodeName.toLowerCase())) {
        content.push(buildNode(sharedState, node, `${key}.${nodeIx}`, {}, null, false));
      }
    }
    else if (node.nodeType === 3) { // Node.TEXT_NODE
      if (node.textContent?.trim()) {
        node.textContent && content.push(<span key={`${key}.${nodeIx}`}>{node.textContent.trim()}</span>);
      }
    }
  }
  return content;
}

export function getTemplateGetter(elementName: string): (element: Element, name: string, props: any) => void {
  return async (element: Element, name: string, props: any) => {
    const template = getNamedChildElement(element, elementName);
    props[name] = template;
  }
}

/***
 * Returns the first child element with the given name
 * If nodeName is null, the first child element is returned
 */
export function getNamedChildElement(element: Element | null, nodeName: string | null): Element | null {
  let child = element?.firstChild;
  while (child) {
    if (child.nodeType === 1) {
      if (!nodeName || nodeName.toLowerCase() === child.nodeName.toLowerCase()) {
        return child as Element;
      }
    }
    child = child.nextSibling;
  }
  return null;
}

export function getNamedElementInList(list: Array<Node>, nodeName: string): Element | null {
  for (let node of list) {
    if (node.nodeType === 1 && node.nodeName.toLowerCase() === nodeName.toLowerCase()) {
      return node as Element;
    }
  }
  return null;
}

export function getBooleanAttribute(element: Element, name: string, props: any, sharedState: SharedState): void {
  props[name] = (element.hasAttribute(name) && "false" !== element.getAttribute(name)?.toLowerCase()) ? true : false;
}

export function getStringAttribute(element: Element, name: string, props: any, sharedState: SharedState): void {
  if (element.hasAttribute(name)) {
    props[name] = resolveValue(sharedState, element.getAttribute(name) || undefined, false) || "";
  }
}

export function getUnresolvedStringAttribute(element: Element, name: string, props: any, sharedState: SharedState): void {
  if (element.hasAttribute(name)) {
    props[name] = element.getAttribute(name) || "";
  }
}

export function getPartlyResolvedStringAttribute(element: Element, name: string, props: any, sharedState: SharedState): void {
  if (element.hasAttribute(name)) {
    props[name] = resolveValue(sharedState, element.getAttribute(name) || undefined, true) || "";
  }
}

export function getNodeAttributeComponent(element: Element, name: string, props: any, sharedState: SharedState): void {
  const p: { [key: string]: any } = {};
  getNodeAttributeDOM(element, name, p, sharedState);
  if (p[name]) {
    props[name] = buildNode(sharedState, p[name], "", {}, null, false);
  }
}

export function getNodeAttributeDOM(element: Element, name: string, props: any, sharedState: SharedState): void {
  if (element.hasAttribute(name)) {
    const doc: Document | null = getDOMParser().parseFromString(element.getAttribute(name) || "", "text/html")
    const root: Node | null = findRoot(doc);
    if (root) {
      props[name] = root;
    }
  }
  else {
    // The value may come from an element
    let e = getFirstElementChild(element);
    while (e) {
      // Keep the comp_ option for now but remove later
      if (e.nodeName.toLowerCase() === name.toLowerCase()) {
        props[name] = e;
        break;
      }
      e = e.nextElementSibling;
    }
  }
}


export function getNumberAttribute(element: Element, name: string, props: any, sharedState: SharedState): void {
  if (element.hasAttribute(name)) {
    const strValue = element.getAttribute(name);
    const numValue = parseInt(strValue?.trim() || "");
    if (!isNaN(numValue)) {
      props[name] = numValue;
    }
  }
}

export function getObjectAttribute(element: Element, name: string, props: any, sharedState: SharedState): void {
  if (element.hasAttribute(name)) {
    const strValue = element.getAttribute(name);
    if (strValue) {
      try {
        props[name] = JSON.parse(strValue);
      }
      catch (e) {
        console.log(`Failed to parse JSON object ${name} on element: `, element);
        console.log("String value: ", strValue);
        console.log(e);
      }
    }
  }
}

export function getSpacingAttribute(element: Element, name: string, props: any, sharedState: SharedState): void {
  if (element.hasAttribute(name)) {
    let value = 0;
    switch (element.getAttribute(name)) {
      case "0":
      case "1":
        value = 1;
        break;
      case "2":
        value = 2;
        break;
      case "3":
        value = 3;
        break;
      case "4":
        value = 4;
        break;
      case "5":
        value = 5;
        break;
      case "6":
        value = 6;
        break;
      case "7":
        value = 7;
        break;
      case "8":
        value = 8;
        break;
      case "9":
        value = 9;
        break;
      case "10":
        value = 10;
        break;
    }
    props[name] = value;
  }
}

export function getTextFieldVariantAttribute(element: Element, name: string, props: any, sharedState: SharedState): void {
  if (element.hasAttribute(name)) {
    let value = "outlined";
    switch (element.getAttribute(name)) {
      case "outlined":
        value = "outlined";
        break;
      case "filled":
        value = "filled";
        break;
      case "standard":
        value = "standard";
        break;
    }
    props[name] = value;
  }
}


export function getXsAttribute(element: Element, name: string, props: any): void {
  if (element.hasAttribute(name)) {
    let value = 0;
    switch (element.getAttribute(name)) {
      case "1":
        value = 1;
        break;
      case "2":
        value = 2;
        break;
      case "3":
        value = 3;
        break;
      case "4":
        value = 4;
        break;
      case "5":
        value = 5;
        break;
      case "6":
        value = 6;
        break;
      case "7":
        value = 7;
        break;
      case "8":
        value = 8;
        break;
      case "9":
        value = 9;
        break;
      case "10":
        value = 10;
        break;
      case "11":
        value = 11;
        break;
      case "12":
        value = 12;
        break;
    }
    props[name] = value;
  }
}

export function getSxAttribute(element: Element, name: string, props: any): void {
  let value = {};
  if (element.hasAttribute(name)) {
    const json: string | null = element.getAttribute(name);
    if (json) {
      try {
        value = JSON.parse(json);
      }
      catch (e) {
        console.log(`Invalid Sx value in ${element?.nodeName} @${name}`, json);
      }
    }
  }
  props[name] = value;
}

export function getChildNodes(element: Element): Array<Node> {
  const children: Array<Node> = [];
  let child: Node | null = element.firstChild;
  while (child) {
    children.push(child);
    child = child.nextSibling;
  }
  return children;
}

export function buildFromHtmlSource(source: string | null, state: SharedState): JSX.Element | null {
  const doc: Document = getDOMParser().parseFromString(source || "", "text/html");
  const rootNode = findRoot(doc);
  const result: string | JSX.Element | null = rootNode ? buildNode(state, rootNode as Element, "PageLoader", {}, null, false) : null;
  return typeof result === "string" ? <>{result}</> : result;
}