import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns all values of a stored array.
 * 
 *      store: The name of the store
 */
export class StoreArraySource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        return sharedState.storedArrays[params.store || ""] || [];
    }
}