import { memo } from 'react';
import { SharedState } from "../sharedState/SharedState";
import { buildContent } from "../component-lists/helpers";
import { mapMetadataToItem } from "../utils/source-item.utils";

/***
 * Load the metadata for the path indicated in SharedState.path, and add it as the
 * selected property in the shared state.
 * 
 * (This component is a remainder from when the metadata was no bundled with the page
 * and had to be loaded dynamically. It is still used to convert the metadata into
 * a SourceItem and put in the storedItems collection.)
 * In the future it may be removed, and have the metadata always be preloaded into
 * a predefined name in storedItems.
 */
export const StoreMetadata = memo(function StoreMetadata(props: {
  sharedState: SharedState,
  store: string,
  content: Array<Node>
}) {

  const nextState: SharedState = props.sharedState.clone();

  if (props.store && props.sharedState.metadata) {
    nextState.storedItems[props.store] = { ...mapMetadataToItem(props.sharedState.metadata), position: 1 };
  }

  return <>{buildContent(nextState, props.content, "LoadMetadata", [])}</>;
});