import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { ValueModifier } from "../ValueModifier";

/***
 * Prints the value to the console.
 * 
 *  * Params:
 *         message: prints this message along with the array
 */
export class DebugValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) {
        console.log("DebugValueModifier", params.message, value);
        return value?.toString() || "";
    }
}