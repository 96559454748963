import { SharedState } from "../../sharedState/SharedState";
import { StateModifier } from "../StateModifier";

/***
 * Returns a modified SharedState.
 * 
 *  * Params:
 *          name: the proeprty name
 *          value: the new value
 *          change: how to change the value
 */
export class CustomStateModifier implements StateModifier {
    public modify(sharedState: SharedState, params: { [name: string]: string }): SharedState {
        const nextSharedState: SharedState = sharedState.clone();
        switch (params.change) {
            case "set":
                nextSharedState.filters.custom[params.name] = [params.value];
                break;
            case "remove":
                delete nextSharedState.filters.custom[params.name];
                break;
            default:
                return sharedState;
        }
        return nextSharedState;
    }
}

export const customStateModifier = new CustomStateModifier();