import { Metadata, PropertiesModel, ValidityPropertyModel } from "../models/metadata.model";
import { Terms } from "../models/terms.model";
import { Settings } from "../models/settings.model";
import { SourceItem } from "../models/source-item.model";
import { Backend } from "./backend";
import { Configuration } from "../models/configuration.model";
import { Organization } from "../models/organization.model";
import { FilterModel } from "../models/filter.model";
import { getPathToRoot } from "../utils/link.utils";

declare global {
    interface Window { _download_metadata: Array<Metadata>; }
}

/***
 * This is the local backend implementation that uses the downloaded metadata to get data.
 */
export class LocalBackend implements Backend {

    public getName = (): string => {
        return "Local";
    }

    currentPath: string = "/";

    constructor(currentPath: string) {
        this.currentPath = currentPath;
    }

    public getTextfile = async (
        _url: string,
    ): Promise<string> => {
        // This method should not get called in local mode, it is not possible to read
        // files from the local filesystem using JS
        return "";
    }

    public getDirectLink = (
        _site: string,
        _slot: string | null,
        path: string,
        _validity: Array<ValidityPropertyModel>,
        _systemProperties: PropertiesModel,
    ): string => {
        return getPathToRoot(this.currentPath) + path.startsWith("/") ? path.substring(1) : path;
    }

    public getAppLink = (
        _sitePath: string,
        path: string,
        validity: Array<ValidityPropertyModel>,
        systemProperties: PropertiesModel,
    ): string => {
        return this.getDirectLink("", null, path, validity, systemProperties);
    }

    public search = async (
        _site: string,
        _org: string | null,
        _cfg: string | null,
        filters: FilterModel,
    ): Promise<Array<Metadata>> => {
        const results: Array<Metadata> = [];
        if (window._download_metadata) {
            for (let metadata of window._download_metadata) {
                if (this.isMatch(metadata, filters)) {
                    results.push(metadata);
                }
            }
        }
        return results;
    }

    public getPropertyValueSet = async (
        _site: string,
        _org: string | null,
        _cfg: string | null,
        filters: FilterModel,
        propertyName: string,
        isValidity: boolean,
        isSystemProperty: boolean,
    ): Promise<Array<SourceItem>> => {
        const hits: { [key: string]: number } = {};
        if (window._download_metadata) {
            for (let metadata of window._download_metadata) {
                if (this.isMatch(metadata, filters)) {
                    if (isValidity) {
                        for (let combo of metadata.validity) {
                            for (let valuepair of combo.combo) {
                                if (valuepair.name === propertyName) {
                                    if (hits[valuepair.value.toString()]) {
                                        hits[valuepair.value.toString()]++;
                                    }
                                    else {
                                        hits[valuepair.value.toString()] = 1;
                                    }
                                }
                            }
                        }
                    }
                    else if (isSystemProperty) {
                        const untypedmeta = metadata as any;
                        if (untypedmeta[propertyName]) {
                            if (hits[untypedmeta[propertyName].toString()]) {
                                hits[untypedmeta[propertyName].toString()]++;
                            }
                            else {
                                hits[untypedmeta[propertyName].toString()] = 1;
                            }
                        }
                    }
                    else {
                        if (metadata.properties[propertyName]) {
                            for (let value of metadata.properties[propertyName]) {
                                if (hits[value.toString()]) {
                                    hits[value.toString()]++;
                                }
                                else {
                                    hits[value.toString()] = 1;
                                }
                            }
                        }
                    }
                }
            }
        }
        // Convert to source items
        const items: Array<SourceItem> = [];
        for (let key in hits) {
            items.push({
                props: {
                    info: {
                        count: [hits[key]],
                    },
                },
                value: key,
            });
        }
        return items;
    }

    public getPropertyNames = async (
        _site: string,
        _org: string | null,
        _cfg: string | null,
        filters: FilterModel,
    ): Promise<Array<SourceItem>> => {
        const nameItems: Array<SourceItem> = [];
        if (window._download_metadata) {
            for (let metadata of window._download_metadata) {
                if (this.isMatch(metadata, filters)) {
                    for (let key in metadata.properties) {
                        if (nameItems.filter((nameItem) => nameItem.value === key).length === 0) {
                            nameItems.push({ value: key, props: {} });
                        }
                    }
                }
            }
        }
        return nameItems;
    }

    public getTerms = async (
        site: string,
        slot: string | null,
    ): Promise<Terms> => {
        return { terms: {}, site: "", slot: "" };
    }

    public getConfiguration = async (
        _site: string,
        _slot: string | null,
        _id: string,
    ): Promise<Configuration> => {
        // Local downloads should not use configurations
        return { organizationId: "", site: "", slot: "", name: "", properties: {}, custom: {}, system: {}, validity: {} };
    }

    public getOrganization = async (
        _site: string,
        _slot: string | null,
        _id: string,
    ): Promise<Organization> => {
        // Local downloads should not use organizations
        return { site: "", slot: "", name: "", properties: {}, custom: {}, system: {}, validity: {} };
    }

    public getSettings = async (
        site: string,
        slot: string | null,
        orgId: string | null,
        cfgId: string | null,
    ): Promise<Settings> => {
        return { site: "", slot: "" };
    }

    public submitForm = async (
        _site: string,
        _name: string,
        _data: { [key: string]: string | boolean },
    ): Promise<{ success: boolean, message: string | null }> => {
        // Local downloads should not use forms
        return { success: true, message: "" };
    }

    public getDownloadLink = (
        site: string,
        slot: string | null,
        items: Array<SourceItem>,
    ): string => {
        // Local downloads should not use download links
        return "";
    }

    private arrayContainsSubstring(array: Array<string>, substring: string): boolean {
        for (let item of array) {
            if (item.indexOf(substring) >= 0) {
                return true;
            }
        }
        return false;
    }

    private isMatch(metadata: Metadata, filters: FilterModel): boolean {
        if (filters.path && metadata.path !== filters.path) {
            return false;
        }
        if (filters.slot && metadata.slot !== filters.slot) {
            return false;
        }
        if (filters.text
            && !this.arrayContainsSubstring(metadata.words.keywords, filters.text)
            && !this.arrayContainsSubstring(metadata.words.h1, filters.text)
            && !this.arrayContainsSubstring(metadata.words.h2, filters.text)
            && !this.arrayContainsSubstring(metadata.words.p, filters.text)) {
            return false;
        }
        if (filters.contentType && metadata.contentType !== filters.contentType) {
            return false;
        }
        if (filters.includes && metadata.includes.indexOf(filters.includes) < 0) {
            return false;
        }
        if (filters.custom) {
            for (let key in filters.custom) {
                // At least one of the values must match
                if (!metadata.properties[key]
                    || metadata.properties[key].filter((value) => filters.custom[key].includes(value)).length === 0) {
                    return false;
                }
            }
        }
        if (filters.system) {
            const untypedmeta = metadata as any;
            for (let key in filters.system) {
                if (untypedmeta[key] && !filters.system[key].includes(untypedmeta[key])) {
                    return false;
                }
            }
        }
        if (filters.validity) {
            if (metadata.validity) {
                for (let filterValidityProp of filters.validity) {
                    // All values of at least one of the combos must match at least one of the values in the metadata
                    let found = false;
                    for (let combomodel of metadata.validity) {
                        if (combomodel.combo.filter((valuepair) => (filterValidityProp.name
                            === valuepair.name && filterValidityProp.value === valuepair.value)).length) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

}