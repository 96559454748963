import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns a list of all property values of a specified category in a source item
 * 
 * Params:
 *      store: the name of the store to load filter values from
 *      type: the property category
 *      name: the name of custom property
 */
export class PropertyValuesSource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        const result: Array<SourceItem> = [];
        const props = sharedState.storedItems[params.store]?.props[params.type];
        if (props) {
            const values = props[params.name];
            if (values) {
                for (let ix = 0; ix < values.length; ix++) {
                    const value = values[ix];
                    result.push({
                        state: sharedState,
                        value: value,
                        props: {},
                        position: ix,
                    });
                }
            }
        }
        return result;
    };
}