import { SharedState } from "../../sharedState/SharedState";
import { StateModifier } from "../StateModifier";

/***
 * Returns a modified SharedState.
 * 
 *  * Params:
 *          value: the new value
 *          change: how to change the value
 */
export class OrganizationStateModifier implements StateModifier {
    public modify(sharedState: SharedState, params: { [name: string]: string }): SharedState {
        const nextSharedState: SharedState = sharedState.clone();
        switch (params.change) {
            case "set":
                nextSharedState.organization = params.value;
                break;
            case "remove":
                nextSharedState.organization = null;
                break;
            default:
                return sharedState;
        }
        return nextSharedState;
    }
}

export const organizationStateModifier = new OrganizationStateModifier();