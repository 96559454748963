import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { ValueModifier } from "../ValueModifier";

/***
 * Surround the value with the specified text before and after.
 * 
 *  * Params:
 *     text: the surrounding text
 */
export class SurroundValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) {
        return (params.text || "") + (value || "").toString() + (params.text || "");
    }
}