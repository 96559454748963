import { memo } from 'react';
import { buildContent } from '../component-lists/helpers';
import { CssClassRule, SharedState } from "../sharedState/SharedState";

/***
 * A component that adds a CSS class to an element based on a rule.
 * The rule can use data in the sharedState to determine whether the class should be added.
 * The rule is evaluated by the renderNode helper function.
 */
export const CssClassRules = memo(function CssClassRules(props: { sharedState: SharedState, content: Array<Node> }): JSX.Element {

  const parseRule = (ruleNode: Element): CssClassRule | null => {
    const className = ruleNode.getAttribute("className");
    const id = ruleNode.getAttribute("applyToId");
    const rule = ruleNode.getAttribute("rule");
    return (className && id && rule && { className: className, id: id, rule: rule }) || null;
  };

  const rules: Array<CssClassRule> = [];
  const contentNodes: Array<Node> = [];
  if (props.content) {
    for (let node of props.content) {
      if (node.nodeType === 1 && node.nodeName.toLowerCase() === 'rule') {
        const rule = parseRule(node as Element);
        rule && rules.push(rule);
      }
      else {
        contentNodes.push(node);
      }
    }
  }

  const nextState = rules.length ? Object.assign(props.sharedState.clone(), { cssClassRules: rules }) : props.sharedState;

  return <>{buildContent(nextState, contentNodes, `css-class-rule`, [])}</>;
});