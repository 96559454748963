/***
 * Value modifiers are used to transform values before they are displayed.
 */

import { PropertyValue } from "../models/metadata.model";
import { SharedState } from "../sharedState/SharedState";
import { BooleanValueModifier } from "./valuemodifier/BooleanValueModifier";
import { DebugValueModifier } from "./valuemodifier/DebugValueModifier";
import { LabelValueModifier } from "./valuemodifier/LabelValueModifier";
import { LinkAppValueModifier } from "./valuemodifier/LinkAppValueModifier";
import { LinkDirectValueModifier } from "./valuemodifier/LinkDirectValueModifier";
import { LocalizeDateValueModifier } from "./valuemodifier/LocalizeDateValueModifier";
import { LocalizeValueModifier } from "./valuemodifier/LocalizeValueModifier";
import { SurroundValueModifier } from "./valuemodifier/SurroundValueModifier";


export type ValueModifier = {
    modify: (sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) => string;
}

export type ValueModifierRequest = {
    modifier: ValueModifier | null;
    params: { [name: string]: string };
}

export const modifierMap: Array<{ modifier: ValueModifier, regex: RegExp }> = [
    {
        modifier: new BooleanValueModifier(),
        regex: /^boolean$/,
    },
    {
        modifier: new DebugValueModifier(),
        regex: /^debug\.(?<message>.*)$/,
    },
    {
        modifier: new LabelValueModifier(),
        regex: /^label\.(?<type>.*)$/,
    },
    {
        modifier: new LinkDirectValueModifier(),
        regex: /^link\.direct\.(?<suppress>.*)$/,
    },
    {
        modifier: new LinkAppValueModifier(),
        regex: /^link\.app\.(?<suppress>.*)$/,
    },
    {
        modifier: new LocalizeValueModifier(),
        regex: /^localize\.(?<lang>.*)$/,
    },
    {
        modifier: new LocalizeDateValueModifier(),
        regex: /^localizedate\.(?<lang>.*)$/,
    },
    {
        modifier: new SurroundValueModifier(),
        regex: /^surround\.(?<text>.+)$/,
    },
]

function removeProtect(params: { [key: string]: string }): { [key: string]: string } {
    const result: { [key: string]: string } = {};
    for (const key in params) {
        result[key] = params[key]?.replaceAll("\\$", "$").replaceAll("\\;", ";").replaceAll("\\|", "|").replaceAll("\\.", ".");
    }
    return result;
}
export const getValueModifierRequest = (specification: string): ValueModifierRequest => {
    for (const def of modifierMap) {
        const result = def.regex.exec(specification);
        if (result) {
            return { modifier: def.modifier, params: removeProtect(result.groups || {}) };
        }
    };
    return { modifier: null, params: {} };
}
