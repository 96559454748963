import { Metadata } from "../../../models/metadata.model";
import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { mapMetadataToItem } from "../../../utils/source-item.utils";
import { ArraySource } from "../../ArraySource";

/***
 * Returns all metadata objects that match the current filter but with the validity
 * specified by a baseline defined in sharedState.
 * 
 *  * Params: (none)
 */
export class QueryBaselineSource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }): Promise<Array<SourceItem>> {
        const baselineState = sharedState.clone();
        for (let v of sharedState.diffBase) {
            baselineState.clearOneBaseline(v.name);
            baselineState.setOneValidity(v.name, v.value);
        }
        const searchlist: Array<Metadata> = sharedState.site?.name ? await sharedState.backend.search(
            sharedState.site.name, sharedState.organization, sharedState.configuration, baselineState.filters) : [];
        return searchlist.map((hit, ix) => {
            return { state: baselineState, ...mapMetadataToItem(hit), position: ix + 1 };
        });
    }
}