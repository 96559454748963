import { memo, useEffect, useState } from 'react';
import { buildNode } from '../component-lists/helpers';
import { SharedState } from "../sharedState/SharedState";
import { getDOMParser } from '../utils/dom.utils';

/***
 * A component that renders an image.
 * For most images this is not needed, but for SVG images it is needed to render them inline.
 * Otherwise just use an img tag directly and use the "DirectLinkValueModifier" in the src attribute
 * to get the correct URL.
 */
export const IncludeImage = memo(function IncludeImage(props: {
  sharedState: SharedState,
  path: string,
  alt: string,
  width: string,
  height: string,
  inline: boolean,
}) {

  const [href, setHref] = useState<string>();
  const [svg, setSvg] = useState<Document>();

  const isSvg = props.path.endsWith(".svg");

  useEffect(() => {
    if (props.sharedState.isClient) {
      if (props.sharedState.site?.name && props.path) {
        setHref(props.sharedState.backend.getDirectLink(props.sharedState.site.name, props.sharedState.filters.slot || "production",
          props.path, props.sharedState.filters.validity, props.sharedState.filters.system));
      }
    }
  }, [props.sharedState, props.path, props.sharedState.isClient])

  useEffect(() => {
    if (props.sharedState.isClient) {
      async function loadSvg(): Promise<void> {
        if (href) {
          const svgText = await props.sharedState.backend.getTextfile(href);
          setSvg(getDOMParser().parseFromString(svgText, "text/xml"));
        }
      }
      if (isSvg) {
        loadSvg();
      }
    }
  }, [href, isSvg, props.sharedState.isClient, props.sharedState.backend])

  if (isSvg && svg && props.inline) {
    return <>{buildNode(props.sharedState, svg.documentElement, "0", {}, null, false)}</>
  }
  else {
    return <img
      src={href}
      alt={props.alt}
      height={props.height || ''}
      width={props.width || ''}
      loading={"lazy"} />
  }
});