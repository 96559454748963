import isEmpty from "lodash/isEmpty"
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import { memo, useState } from "react";
import { buildContent, buildNode } from "../component-lists/helpers";
import { SharedState } from "../sharedState/SharedState";
import { getFirstElementChild } from "../utils/dom.utils";

/***
 * A component that renders a form and handles callbacks for buttons in the form.
 */
export const FormManager = memo(function FormManager(props: {
    sharedState: SharedState,
    name: string,
    outsideFormTemplate: Element,
    requiredFields: string,
    content: Array<Node>,
}): JSX.Element {

    /***
     * Snackbar definitions
     */
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("");
    const [snackBarSeverity, setSnackBarSeverity] = useState<"success" | "error">("success");

    const openSnackBar = (message: string, severity: "success" | "error") => {
        setSnackBarMessage(message);
        setSnackBarSeverity(severity);
        setSnackBarOpen(true);
    }

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    }

    /***
     * Dialog definitions
     */
    const [isOpen, setOpen] = useState<boolean>(false);
    const [fields, setFields] = useState<{ [key: string]: string | boolean }>({});

    /**
     * Split the required fields into an array of strings
     */
    const requiredFields: Array<string> = props.requiredFields ? props.requiredFields.split(",").map((field) => field.trim()) : [];

    /***
    * The form is valid if there are no required fields or all required fields are filled in
    */
    const isValidForm = !isEmpty(fields) && requiredFields.every((field) => fields[field]);

    const openDialog = () => {
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    const changeFieldValue = (state: SharedState, args: { [key: string]: any }) => {
        if (args.field && args.value) {
            const newFields: { [key: string]: string | boolean } = Object.assign({}, fields);
            newFields[args.field] = args.value;
            setFields(newFields);
        }
    }

    const sendFormToServer = async () => {
        if (props.sharedState.site) {
            // Only send the form if at least one field is filled in and all required fields are filled in
            if (isValidForm) {
                const response = await props.sharedState.backend.submitForm(props.sharedState.site.name, props.name, fields);
                if (response?.success) {
                    openSnackBar("Your message has been registrered.", "success");
                    closeDialog();
                }
                else {
                    openSnackBar(`There was a problem sending your message: ${response.message}`, "error");
                }
            }
        }
    }

    const nextState = props.sharedState.clone();
    nextState.callbacks["open-dialog"] = { func: openDialog };
    nextState.callbacks["close-dialog"] = { func: closeDialog };
    nextState.callbacks["set-form-field"] = { func: changeFieldValue };
    nextState.callbacks["submit-form"] = { func: sendFormToServer };
    nextState.isValidForm = isValidForm;

    return <>
        {buildNode(nextState, getFirstElementChild(props.outsideFormTemplate) || "", "0", {}, null, false)}
        <Dialog open={isOpen} maxWidth={"md"} fullWidth={true}>
            {buildContent(nextState, props.content, "0", ["outsideformtemplate"])}
        </Dialog>
        <Snackbar
            open={snackBarOpen}
            autoHideDuration={6000}
            onClose={handleSnackBarClose}
            message={snackBarMessage}
            action={<>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleSnackBarClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </>}>
            <Alert onClose={handleSnackBarClose} severity={snackBarSeverity} sx={{ width: '100%' }}>{snackBarMessage}</Alert>
        </Snackbar>
    </>;
});