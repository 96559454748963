/***
 * Enum for the different types of items that make up sequences for the LCS algorithm
 */
export enum SequenceItemType {
    STARTTAG,
    ENDTAG,
    WORD,
}

export class InsertionPoint {
    node: Node;
    characterIndex: number;

    constructor(node: Node, characterIndex: number) {
        this.node = node;
        this.characterIndex = characterIndex;
    }
}

/***
 * Base class for items that make up sequences for the LCS algorithm
 */
export abstract class SequenceItem {
    itemType: SequenceItemType;
    matched: boolean;
    expanded: boolean;

    constructor(itemType: SequenceItemType) {
        this.itemType = itemType;
        this.matched = false;
        this.expanded = false;
    }

    abstract isExpandable(): boolean;
    abstract expandSize(): number;
    abstract expand(): Array<SequenceItem>;
    abstract depthModifier(): number;
    abstract getValue(): string;
    abstract addDisplayNode(ancestors: Array<Element>, isDeleted: boolean, isInserted: boolean): void;

    static getRootInsertionPoint(root: Element): InsertionPoint {
        return new InsertionPoint(root, 0);
    }

    getHash(): string | null {
        return null;
    }

    getItemType(): SequenceItemType {
        return this.itemType;
    }

    isEqual(other: SequenceItem): boolean {
        if (other.getItemType() !== this.getItemType()) {
            return false;
        }
        if (this.getHash() && this.getHash() !== other.getHash()) {
            return false;
        }
        return other.getValue() === this.getValue();
    }

    setMatched(): boolean {
        if (!this.matched) {
            this.matched = this.isExpandable() && !this.expanded;
        }
        return this.matched;
    }

    subSequenceSize(): number {
        return 1;
    }
}

export class SequenceStarttagItem extends SequenceItem {
    element: Element;

    constructor(element: Element) {
        super(SequenceItemType.STARTTAG);
        this.element = element;
    }

    isExpandable(): boolean {
        return !this.matched && !this.expanded;
    }

    expandSize(): number {
        return this.element.childNodes.length;
    }

    subSequenceSize(): number {
        // return this.matched ? calculateSubSequenceLength(this.element) : 1;
        return this.expanded ? 1 : parseInt(this.element.getAttribute("data-diff-optimize-count") || "");
    }

    expand(): Array<SequenceItem> {
        const newItems: Array<SequenceItem> = [];
        let child: Node | null = this.element.firstChild;
        while (child) {
            if (child.nodeType === 1) { // Node.ELEMENT_NODE
                newItems.push(new SequenceStarttagItem(child as Element));
            } else if (child.nodeType === 3 && (child.nodeValue || "").trim()) { // Node.TEXT_NODE
                const words: Array<string> = (child.nodeValue || "").trim().split(' ');
                for (let wordIx = 0; wordIx < words.length; wordIx++) {
                    newItems.push(new SequenceWordItem(words[wordIx]));
                }
            }
            child = child.nextSibling;
        }
        newItems.push(new SequenceEndtagItem(this));
        this.expanded = true;
        return newItems;
    }

    depthModifier(): number {
        return this.expanded ? 1 : 0;
    }

    getHash(): string | null {
        return this.expanded ? null : this.element.getAttribute("data-diff-optimize-hash") || "";
    }

    getValue(): string {
        return this.expanded ? this.element.nodeName : this.element.getAttribute("data-diff-optimize-value") || "";
    }

    addDisplayNode(ancestors: Array<Element>, isDeleted: boolean, isInserted: boolean): void {
        let parent = ancestors[ancestors.length - 1];
        if (parent.getAttribute("data-diff") === "markup") {
            parent = ancestors[ancestors.length - 2];
        }
        if (parent?.ownerDocument) {
            let element: Element = parent.ownerDocument?.importNode(this.element.cloneNode(!this.expanded) as Element, !this.expanded);
            if (this.expanded) {
                if (isDeleted || isInserted) {
                    element.setAttribute("data-diff", "markup");
                }
            }
            else {
                if (isDeleted) {
                    element.setAttribute("data-diff", "del");
                }
                if (isInserted) {
                    element.setAttribute("data-diff", "ins");
                }
            }
            parent.appendChild(element);
            if (this.expanded) {
                ancestors.push(element);
            }
        }
    }

}

export class SequenceEndtagItem extends SequenceItem {
    starttag: SequenceStarttagItem;

    constructor(starttag: SequenceStarttagItem) {
        super(SequenceItemType.ENDTAG);
        this.starttag = starttag;
    }

    isExpandable(): boolean {
        return false;
    }

    expandSize(): number {
        return 0;
    }

    expand(): Array<SequenceItem> {
        return [];
    }

    depthModifier(): number {
        return -this.starttag.depthModifier();
    }

    getValue(): string {
        return this.starttag.getValue();
    }

    setMatched(): boolean {
        if (!this.matched) {
            this.starttag.matched && (this.matched = !this.expanded);
        }
        return this.matched;
    }

    addDisplayNode(ancestors: Array<Element>, isDeleted: boolean, isInserted: boolean): void {
        ancestors.pop();
    }

}

// export class SequenceStartTextItem extends SequenceItem {
//     textNode: Text;

//     constructor(textNode: Text) {
//         super(SequenceItemType.STARTTEXT);
//         this.textNode = textNode;
//     }

//     isExpandable(): boolean {
//         // return false;
//         return !this.matched && !this.expanded;
//     }

//     expandSize(): number {
//         // return 0;
//         return (this.textNode.nodeValue || "").length;
//     }

//     expand(): Array<SequenceItem> {
//         // return [];

//         const newItems: Array<SequenceItem> = [];
//         for (let charIx = 0; charIx < (this.textNode.nodeValue || "").length; charIx++) {
//             newItems.push(new SequenceCharacterItem(this, charIx, (this.textNode.nodeValue || "")[charIx]));
//         }
//         newItems.push(new SequenceEndTextItem(this));
//         this.expanded = true;
//         return newItems;
//     }

//     depthModifier(): number {
//         // return 0;
//         return this.expanded ? 1 : 0;
//     }

//     getValue(): string {
//         return this.expanded ? "#text" : this.textNode.nodeValue || "";
//     }

//     subSequenceSize(): number {
//         return this.expanded ? 1 : (this.textNode.nodeValue || "").length;
//     }

//     addDisplayNode(ancestors: Array<Element>, isDeleted: boolean, isInserted: boolean): void {
//         if (!this.expanded) {
//             const parent = ancestors[ancestors.length - 1];
//             if (parent?.ownerDocument) {

//                 let insertInElement: Element = parent;
//                 let diffAttr: string | null = null;
//                 isDeleted && (diffAttr = "del");
//                 isInserted && (diffAttr = "ins");

//                 if (diffAttr) {
//                     if (parent.lastChild?.nodeName === "SPAN" && (<Element>parent.lastChild).getAttribute("data-diff") === diffAttr) {
//                         insertInElement = <Element>parent.lastChild;
//                     }
//                     else {
//                         insertInElement = parent.ownerDocument.createElement("span");
//                         insertInElement.setAttribute("data-diff", diffAttr);
//                         parent.appendChild(insertInElement);
//                     }
//                 }

//                 insertInElement.appendChild(parent.ownerDocument.createTextNode(this.textNode?.textContent || ""));
//                 insertInElement.normalize();
//             }

//         }

//     }


// }

// export class SequenceEndTextItem extends SequenceItem {
//     starttext: SequenceStartTextItem;

//     constructor(starttext: SequenceStartTextItem) {
//         super(SequenceItemType.ENDTEXT);
//         this.starttext = starttext;
//     }

//     isExpandable(): boolean {
//         return false;
//     }

//     expandSize(): number {
//         return 0;
//     }

//     expand(): Array<SequenceItem> {
//         return [];
//     }

//     depthModifier(): number {
//         return -this.starttext.depthModifier();
//     }

//     getValue(): string {
//         return this.starttext.getValue();
//     }

//     setMatched(): boolean {
//         if (!this.matched) {
//             this.starttext.matched && (this.matched = !this.expanded);
//         }
//         return this.matched;
//     }

//     addDisplayNode(ancestors: Array<Element>, isDeleted: boolean, isInserted: boolean): void { }

// }


export class SequenceWordItem extends SequenceItem {
    word: string;

    constructor(word: string) {
        super(SequenceItemType.WORD);
        this.word = word;
    }

    isExpandable(): boolean {
        return false;
    }

    expandSize(): number {
        return 0;
    }

    expand(): Array<SequenceItem> {
        return [];
    }

    depthModifier(): number {
        return 0;
    }

    getValue(): string {
        return this.word;
    }

    subSequenceSize(): number {
        return this.word.length;
    }

    addDisplayNode(ancestors: Array<Element>, isDeleted: boolean, isInserted: boolean): void {
        const parent = ancestors[ancestors.length - 1];
        if (parent?.ownerDocument) {

            let insertInElement: Element = parent;
            let diffAttr: string | null = null;
            isDeleted && (diffAttr = "del");
            isInserted && (diffAttr = "ins");

            if (diffAttr) {
                if (parent.lastChild?.nodeName?.toLowerCase() === "span" && (parent.lastChild as Element).getAttribute("data-diff") === diffAttr) {
                    insertInElement = parent.lastChild as Element;
                    if (insertInElement.childNodes.length) {
                        insertInElement.appendChild(parent.ownerDocument.createTextNode(' '));
                    }
                }
                else {
                    if (parent.childNodes.length) {
                        insertInElement.appendChild(parent.ownerDocument.createTextNode(' '));
                    }
                    insertInElement = parent.ownerDocument.createElement("span");
                    insertInElement.setAttribute("data-diff", diffAttr);
                    parent.appendChild(insertInElement);
                }
            }
            else {
                if (parent.childNodes.length) {
                    insertInElement.appendChild(parent.ownerDocument.createTextNode(' '));
                }
            }
            insertInElement.appendChild(parent.ownerDocument.createTextNode(this.word));
        }
    }
}



// export class SequenceCharacterItem extends SequenceItem {
//     text: SequenceStartTextItem;
//     characterIndex: number;
//     character: string;

//     constructor(text: SequenceStartTextItem, characterIndex: number, character: string) {
//         super(SequenceItemType.CHARACTER);
//         this.text = text;
//         this.characterIndex = characterIndex;
//         this.character = character;
//     }

//     isExpandable(): boolean {
//         return false;
//     }

//     expandSize(): number {
//         return 0;
//     }

//     expand(): Array<SequenceItem> {
//         return [];
//     }

//     depthModifier(): number {
//         return 0;
//     }

//     getValue(): string {
//         return this.character;
//     }

//     addDisplayNode(ancestors: Array<Element>, isDeleted: boolean, isInserted: boolean): void {
//         const parent = ancestors[ancestors.length - 1];
//         if (parent?.ownerDocument) {

//             let insertInElement: Element = parent;
//             let diffAttr: string | null = null;
//             isDeleted && (diffAttr = "del");
//             isInserted && (diffAttr = "ins");

//             if (diffAttr) {
//                 if (parent.lastChild?.nodeName === "SPAN" && (<Element>parent.lastChild).getAttribute("data-diff") === diffAttr) {
//                     insertInElement = <Element>parent.lastChild;
//                 }
//                 else {
//                     insertInElement = parent.ownerDocument.createElement("span");
//                     insertInElement.setAttribute("data-diff", diffAttr);
//                     parent.appendChild(insertInElement);
//                 }
//             }
//             console.log("   CHAR", this.character, parent.nodeName);
//             insertInElement.appendChild(parent.ownerDocument.createTextNode(this.character));
//             insertInElement.normalize();
//         }
//     }
// }


export class BacktrackItem {
    baselineItem: SequenceItem;
    currentItem: SequenceItem;

    constructor(refItem: SequenceItem, addItem: SequenceItem) {
        this.baselineItem = refItem;
        this.currentItem = addItem;
    }
}