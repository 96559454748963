/***
 * Difference sources retrieve information about a comparison between two arrays of metadata.
 * The arrays must be previously stored in the shared state, using the <StoreArray> component.
 */

import { SearchDifference, compareSearch } from "../../../diff/search-compare";
import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns all metadata in a calculated difference, per category.
 * 
 * Params:
 *    baseline: The name of the baseline array, in stored arrays
 *    current: The name of the current array, in stored arrays
 *    category: The name of the requested statistic. Available categories are:
 *       - new: new metadata since the baseline
 *       - removed: removed metadata since the baseline
 *       - modified: modified metadata since the baseline
 */
export class CompareSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        if (params.baseline && params.current) {
            const baselineItems: Array<SourceItem> = sharedState.storedArrays[params.baseline] || [];
            const currentItems: Array<SourceItem> = sharedState.storedArrays[params.current] || [];
            const result: SearchDifference = compareSearch(baselineItems, currentItems);
            switch (params.category) {
                case "new":
                    return result.new.map((item, ix) => { return { ...item, state: sharedState, position: ix + 1 }; });
                case "removed":
                    return result.removed.map((item, ix) => { return { ...item, state: sharedState, position: ix + 1 }; });
                case "modified":
                    return result.modified.map((item, ix) => { return { ...item, state: sharedState, position: ix + 1 }; });
            }
        }
        return [];
    }
}