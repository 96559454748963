import { SharedState } from "../../sharedState/SharedState";
import { StateModifier } from "../StateModifier";

/***
 * Returns a modified SharedState.
 * 
 *  * Params:
 *          name: the property name
 *          value: the new value
 *          change: how to change the value
 */
export class ValidityStateModifier implements StateModifier {
    public modify(sharedState: SharedState, params: { [name: string]: string }): SharedState {
        const nextSharedState: SharedState = sharedState.clone();
        if (params.name) {
            switch (params.change) {
                case "set":
                    params.value && nextSharedState.setOneValidity(params.name, params.value);
                    break;
                case "remove":
                    nextSharedState.clearOneValidity(params.name);
                    break;
            }
        }
        return nextSharedState;
    }
}

export const validityStateModifier = new ValidityStateModifier();