import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { ValueModifier } from "../ValueModifier";

/***
 * Localizes the value by lloking it up in the terms list.
 * 
 *  Params:
 *          lang: localize to a particular language, may be omitted to use the current language
 */
export class LocalizeValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) {
        const lang = params.lang || (sharedState.filters.system.lang ? sharedState.filters.system.lang[0].toString() : "");
        const term = value?.toString() || "";
        if (lang && sharedState.terms?.[term]?.[lang]) {
            return sharedState.terms[term][lang];
        }
        return term;
    }
}