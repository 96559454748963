import { SourceItem } from "../../models/source-item.model";
import { SharedState } from "../../sharedState/SharedState";
import { ArrayModifier } from "../ArrayModifier";

/***
 * Prints the array to the console.
 * 
 *  * Params:
 *         message: prints this message along with the array
 */
export class DebugArrayModifier implements ArrayModifier {
    public modify(sharedState: SharedState, array: Array<SourceItem>, params: { [name: string]: string }): Array<SourceItem> {
        console.log("DebugArrayModifier", params.message, array);
        return array;
    }
}