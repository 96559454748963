import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { ValueModifier } from "../ValueModifier";

/***
 * Transforms the value to a string value representing a boolean, according to these rules:
 *      null -> "false"
 * 
 *      String values:
 *          empty string -> "false"
 *          all other strings -> "true"
 *      Number values:
 *          0 -> "false"
 *          all other numbers -> "true"
 *      Date values:
 *          all values -> "true"
 *      Boolean values:
 *          true -> "true"
 *          false -> "false"
 * 
 * Params:
 *      (none)
 */
export class BooleanValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }): string {
        return (!!value).toString();
    }
}